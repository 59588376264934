var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import { Sortable } from '../../Behavior';
import styled from '../../styled-components';
import { ItemTypes } from './ItemTypes';
import { Sendable } from './Sendable';
export var Thumbnail = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  display: flex;\n  margin: 2px;\n  position: relative;\n  min-width: 110px;\n  border: 4px solid\n    ", ";\n"], ["\n  list-style: none;\n  display: flex;\n  margin: 2px;\n  position: relative;\n  min-width: 110px;\n  border: 4px solid\n    ", ";\n"])), function (p) { return (p.primary ? p.theme.colorActionable : 'rgba(0,0,0,0.02)'); });
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n  opacity: ", ";\n  max-height: 120px;\n  width: auto;\n"], ["\n  display: flex;\n  height: auto;\n  opacity: ", ";\n  max-height: 120px;\n  width: auto;\n"])), function (p) { return (p.disabled ? 0.2 : 1); });
export var ImagePreview = function (_a) {
    var id = _a.id, canDrop = _a.canDrop, primary = _a.primary, preview = _a.preview, onSort = _a.onSort, onFinishSort = _a.onFinishSort, onDelete = _a.onDelete, index = _a.index, progress = _a.progress;
    var _b = useState(null), localPreview = _b[0], setLocalPreview = _b[1];
    if (!localPreview && preview && preview.file) {
        setLocalPreview(URL.createObjectURL(preview.file));
    }
    useEffect(function () {
        if (localPreview) {
            URL.revokeObjectURL(localPreview);
        }
    }, [localPreview]);
    return (React.createElement(Sortable, { id: id, index: index, type: ItemTypes.Image, onSort: onSort, onFinishSort: onFinishSort, sortDirection: "horizontal" },
        React.createElement(Thumbnail, { primary: primary },
            React.createElement(Sendable, { onDelete: onDelete, deletable: canDrop, preview: preview, progress: progress }, preview ? (React.createElement(Image, { src: localPreview || preview.downloadUrl || '', disabled: false })) : null))));
};
var templateObject_1, templateObject_2;
