var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormikContext } from 'formik';
import * as React from 'react';
import { DecorativeCheckBox, DecorativeCheckboxIndicator, DecorativeCheckBoxSmall, } from '../Form';
import { FieldGroupAppearanceSize, FieldGroupRenderer, } from './FieldGroupFieldRenderer';
var defaultContainerForAppearance = function (appearance) {
    switch (appearance) {
        case FieldGroupAppearanceSize.Small:
            return DecorativeCheckBoxSmall;
        default:
            return DecorativeCheckBox;
    }
};
export function CheckBoxGroupRenderer() {
    var Renderer = FieldGroupRenderer();
    var GeneratedRenderer = function (props) {
        var form = useFormikContext();
        var onChange = function (_a) {
            var name = _a.name, value = _a.value, fieldValue = _a.fieldValue;
            var fields = typeof fieldValue !== 'object' ? [fieldValue || ''] : fieldValue;
            form.setFieldValue(name, fields.includes(value)
                ? fields.filter(function (v) { return v !== value; })
                : fields.concat(value));
        };
        var isChecked = function (_a) {
            var fieldValue = _a.fieldValue, value = _a.value;
            var fields = typeof fieldValue !== 'object' ? [fieldValue || ''] : fieldValue;
            return fields.indexOf(value) > -1;
        };
        return (React.createElement(Renderer, __assign({}, props, { inputType: "checkbox", isChecked: isChecked, onChange: onChange, container: props.container ||
                defaultContainerForAppearance(props.appearanceSize || FieldGroupAppearanceSize.Default), indicator: props.indicator || DecorativeCheckboxIndicator })));
    };
    return GeneratedRenderer;
}
