var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { remCalc } from 'utils';
export var Code = function (_a) {
    var _b = _a.style, style = _b === void 0 ? {} : _b, children = _a.children;
    return (React.createElement("code", { style: __assign({ padding: remCalc(10), fontSize: remCalc(10) }, style) }, children));
};
Code.displayName = 'Content.Code';
