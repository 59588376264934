import { removeCookie, setCookie } from 'react-simple-cookie-store';
import { AccessToken } from './AccessToken';
export var setCredentials = function (_a) {
    var accessToken = _a.accessToken, refreshToken = _a.refreshToken, temporaryAccessToken = _a.temporaryAccessToken;
    if (temporaryAccessToken) {
        removeCookie(AccessToken.Access);
        removeCookie(AccessToken.Refresh);
        setCookie(AccessToken.Temporary, temporaryAccessToken, 1);
        return;
    }
    if (accessToken) {
        setCookie(AccessToken.Access, accessToken, 7);
        removeCookie(AccessToken.Temporary);
    }
    if (refreshToken) {
        setCookie(AccessToken.Refresh, refreshToken, 7);
    }
};
