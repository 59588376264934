import React, { useState } from 'react';
import { remCalc, concatStyles } from 'utils';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var SEARCH_BAR_CONTAINER_CLASS = 'flex my-0 px-4 lg:px-6 relative min-width-1 flex-grow max-w-xl';
var SEARCH_BAR_ICON_CONTAINER_CLASS = 'flex absolute top-0 right-0 h-full m-0 py-0 px-6 z-30 text-primary';
var SEARCH_BAR_ICON_CLASS = 'flex m-auto';
var SEARCH_REGION_CLASS = 'flex flex-grow flex-shrink w-auto mt-3 absolute min-w-0 z-20';
var SEARCH_BAR_BLASS = 'flex flex-grow flex-shrink w-auto pt-2 pb-3 px-4 font-body m-auto rounded text-sm leading-none focus:outline-none border-1';
var SEARCH_RESULTS_CLASS = 'absolute bg-white text-body font-body overflow-y-auto border-2 border-actionable pt-8 z-10 flex-grow flex-shrink flex rounded';
export var SearchBar = function (_a) {
    var onSearchUpdate = _a.onSearchUpdate, renderResults = _a.renderResults;
    var _b = useState(''), searchQuery = _b[0], setSearchQuery = _b[1];
    var _c = useState(false), searchActive = _c[0], setSearchActive = _c[1];
    var handleSearchUpdate = function (e) {
        setSearchQuery(e.currentTarget.value);
        if (onSearchUpdate) {
            onSearchUpdate(e.currentTarget.value);
        }
    };
    var handleSearchFocus = function () {
        setSearchActive(true);
    };
    var handleDismissSearch = function () {
        setSearchActive(false);
    };
    return (React.createElement("div", { className: SEARCH_BAR_CONTAINER_CLASS },
        React.createElement("p", { className: SEARCH_BAR_ICON_CONTAINER_CLASS },
            React.createElement(FontAwesomeIcon, { className: SEARCH_BAR_ICON_CLASS, icon: faSearch, size: "1x" })),
        React.createElement("div", { className: SEARCH_REGION_CLASS, style: {
                left: remCalc(8),
                right: remCalc(8),
            } },
            React.createElement("input", { type: "text", name: "search", placeholder: "Search", onChange: handleSearchUpdate, value: searchQuery, autoComplete: "off", onFocus: handleSearchFocus, className: concatStyles([
                    SEARCH_BAR_BLASS,
                    searchActive ? 'border-actionable' : 'border-gray-300',
                ]) })),
        searchActive ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: SEARCH_RESULTS_CLASS, onClick: handleDismissSearch, style: {
                    top: '0.75rem',
                    paddingTop: '3rem',
                    left: remCalc(7),
                    right: remCalc(7),
                    maxHeight: '80vh',
                } }, renderResults ? renderResults() : null),
            React.createElement("div", { onClick: handleDismissSearch, className: "fixed z-0 top-0 left-0 w-screen h-screen" }))) : null));
};
