var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Sentry from '@sentry/browser';
import React from 'react';
import { ErrorView } from './ErrorView';
/**
 * A simple error boundary class that will also log the error
 * and pass it along to sentry.
 */
var FlashError = /** @class */ (function (_super) {
    __extends(FlashError, _super);
    function FlashError(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { eventId: null, hasError: false };
        return _this;
    }
    FlashError.getDerivedStateFromError = function (error) {
        console.log(error);
        return { hasError: true, error: error.message };
    };
    FlashError.prototype.componentDidCatch = function (error, errorInfo) {
        var _this = this;
        Sentry.withScope(function (scope) {
            scope.setExtras(errorInfo);
            var eventId = Sentry.captureException(error);
            _this.setState({ eventId: eventId });
        });
    };
    FlashError.prototype.render = function () {
        return this.state.hasError ? (React.createElement(ErrorView, { error: this.state.error })) : (this.props.children);
    };
    return FlashError;
}(React.Component));
export { FlashError };
