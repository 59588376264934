import React from 'react';
import { concatStyles } from 'utils';
var TABS_CONTAINER_STYLES = 'font-body flex justify-center border-b border-primary';
var TAB_STYLES = 'my-0 mx-4 p-2 border-b-4 text-xs cursor-pointer focus:outline-none';
var DISABLED_STYLES = 'text-contentAreaBorder pointer-events-none';
var SELECTED_TAB = 'text-primary border-primary';
var DESELECTED_TAB = 'border-transparent';
var pickTextColor = function (disabled, selected) {
    if (disabled === void 0) { disabled = false; }
    return disabled ? 'text-contentAreaBorder' : selected ? 'text-primary' : 'text-body';
};
export var Tabs = function (_a) {
    var currentValue = _a.currentValue, tabs = _a.tabs, handleSelect = _a.onSelectTab, className = _a.className, style = _a.style;
    return (React.createElement("div", { className: concatStyles([TABS_CONTAINER_STYLES, className]), style: style }, tabs
        .filter(function (t) { return t; })
        .map(function (t) { return t; })
        .map(function (tab, _) {
        var disabled = tab.disabled;
        var selected = currentValue === tab.value;
        var onClick = function () { return handleSelect(tab); };
        return (React.createElement("button", { onClick: onClick, className: concatStyles([
                TAB_STYLES,
                disabled ? DISABLED_STYLES : '',
                selected ? SELECTED_TAB : DESELECTED_TAB,
                pickTextColor(disabled, selected),
            ]) }, tab.label));
    })));
};
Tabs.displayName = 'Content.Tabs';
