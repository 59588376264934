var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { RequiredIcon } from '../Form';
import * as Indicator from '../Indicators';
import styled, { remCalc } from '../styled-components';
import { InputWrapper } from './FieldContainer';
import { Icon, IconLocation } from './Icon';
import { Text } from './Text';
var DropdownWrapper = styled(InputWrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  flex-shrink: 1;\n"], ["\n  flex-direction: column;\n  flex-shrink: 1;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-family: ", ";\n  flex-grow: 1;\n  width: auto;\n  position: relative;\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  font-family: ", ";\n  flex-grow: 1;\n  width: auto;\n  position: relative;\n  margin-bottom: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, remCalc(12));
var ResultView = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  border: 2px solid ", ";\n  border-top: 0;\n  box-sizing: border-box;\n  box-shadow: 0 0 2px ", ";\n  border-radius: ", ";\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  flex-direction: column;\n  position: ", ";\n  top: calc(100% - 4px);\n  width: 100%;\n  z-index: 2;\n  overflow-y: auto;\n  max-height: ", ";\n  margin-top: ", ";\n"], ["\n  background: ", ";\n  display: flex;\n  border: 2px solid ", ";\n  border-top: 0;\n  box-sizing: border-box;\n  box-shadow: 0 0 2px ", ";\n  border-radius: ", ";\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  flex-direction: column;\n  position: ", ";\n  top: calc(100% - 4px);\n  width: 100%;\n  z-index: 2;\n  overflow-y: auto;\n  max-height: ", ";\n  margin-top: ", ";\n"])), function (p) { return p.theme.colorBodyInverted; }, function (p) { return p.theme.colorActionable; }, function (p) { return p.theme.colorActionable; }, function (p) { return p.theme.borderRadius; }, function (p) { return (p.pushContent ? 'relative' : 'absolute'); }, remCalc(180), function (p) { return (p.pushContent ? '-2px' : '0'); });
var Field = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  background: ", ";\n  color: ", ";\n  border: ", ";\n  border-radius: ", ";\n  font-size: ", ";\n  padding: ", " ", ";\n  flex-grow: 1;\n  width: auto;\n  margin-top: 0;\n  margin-bottom: auto;\n  position: relative;\n  height: auto;\n  box-shadow: ", ";\n"], ["\n  display: flex;\n  background: ", ";\n  color: ", ";\n  border: ",
    ";\n  border-radius: ", ";\n  font-size: ", ";\n  padding: ", " ", ";\n  flex-grow: 1;\n  width: auto;\n  margin-top: 0;\n  margin-bottom: auto;\n  position: relative;\n  height: auto;\n  box-shadow: ",
    ";\n"])), function (p) { return p.theme.colorBodyInverted; }, function (p) { return p.theme.colorBody; }, function (p) {
    return p.active
        ? "2px solid " + p.theme.colorActionable
        : "1px solid " + p.theme.colorContentAreaBorder;
}, function (p) { return p.theme.borderRadius; }, remCalc(14), remCalc(10), remCalc(10), function (p) {
    return p.active ? "0 0 2px " + p.theme.colorActionable : "none";
});
var SearchFieldWrapper = styled(InputWrapper)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  color: ", ";\n  min-width: 0;\n  max-width: 100%;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  color: ", ";\n  min-width: 0;\n  max-width: 100%;\n"])), function (p) { return p.theme.colorPrimary; });
var SearchField = styled(Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", " ", " ", " ", ";\n  margin: 0.5rem 0.5rem 0.5rem 0.5rem;\n  min-width: 0;\n  max-width: 100%;\n"], ["\n  padding: ", " ", " ", " ", ";\n  margin: 0.5rem 0.5rem 0.5rem 0.5rem;\n  min-width: 0;\n  max-width: 100%;\n"])), remCalc(6), remCalc(12), remCalc(6), remCalc(32));
var Label = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  margin: auto 0;\n  opacity: ", ";\n"], ["\n  display: flex;\n  margin: auto 0;\n  opacity: ", ";\n"])), function (p) { return (p.inactive ? 0.75 : 1); });
var Overlay = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 1;\n  height: 100vh;\n  width: 100vw;\n  top: 0;\n  left: 0;\n"], ["\n  position: fixed;\n  z-index: 1;\n  height: 100vh;\n  width: 100vw;\n  top: 0;\n  left: 0;\n"])));
var IconWrap = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  border-radius: ", ";\n  background: ", ";\n  bottom: 0;\n  margin: 0;\n  padding: ", ";\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  flex: 1;\n  font-size: ", ";\n\n  & > span {\n    margin: auto;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  border-radius: ", ";\n  background: ",
    ";\n  bottom: 0;\n  margin: 0;\n  padding: ", ";\n  position: absolute;\n  right: ", ";\n  top: ", ";\n  flex: 1;\n  font-size: ", ";\n\n  & > span {\n    margin: auto;\n  }\n"])), function (p) { return p.theme.colorBodyInverted; }, function (p) { return p.theme.borderRadius; }, function (p) {
    return p.active ? p.theme.colorActionable : p.theme.colorPrimary;
}, remCalc(8), function (p) { return (p.active ? remCalc(-1) : 0); }, function (p) { return (p.active ? remCalc(-1) : 0); }, remCalc(16));
var useState = React.useState;
export var Dropdown = function (_a) {
    var pushContent = _a.pushContent, hideSearch = _a.hideSearch, value = _a.value, children = _a.children, placeholder = _a.placeholder, onDismiss = _a.onDismiss, loading = _a.loading, required = _a.required;
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var _c = useState(''), searchValue = _c[0], setSearchValue = _c[1];
    var updateSearch = debounce(function (updatedVal) {
        setSearchValue(updatedVal);
    }, 300, { leading: false, trailing: true });
    var onClick = function () { return setActive(!active); };
    var onChange = function (e) {
        return updateSearch(e.target.value);
    };
    var dismiss = function () {
        setActive(false);
        setSearchValue('');
        if (onDismiss) {
            onDismiss();
        }
    };
    var hasValue = typeof value === 'string' && value.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(DropdownWrapper, { as: "div" },
                React.createElement(Field, { onClick: onClick, active: active },
                    React.createElement(Label, { inactive: !hasValue },
                        hasValue ? value : placeholder || '',
                        ' ',
                        required && React.createElement(RequiredIcon, null)),
                    React.createElement(IconWrap, { active: active, as: "span" },
                        React.createElement(FontAwesomeIcon, { icon: faChevronDown }))),
                active ? (React.createElement(ResultView, { pushContent: pushContent },
                    hideSearch ? null : (React.createElement(SearchFieldWrapper, null,
                        React.createElement(SearchField, { type: "search", onChange: onChange }),
                        React.createElement(Icon, { icon: faSearch, location: IconLocation.Start, align: "left" }),
                        loading ? React.createElement(Indicator.Spinner, { className: "mr-2" }) : null)),
                    children(searchValue, dismiss))) : null)),
        !pushContent && active ? React.createElement(Overlay, { onClick: dismiss }) : null));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
