var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field, getErrorMessage } from './Field';
import { TextArea } from './TextArea';
var hasError = function (error) { return (error !== null && error !== void 0 ? error : '').length > 0; };
var getPaddingForField = function (hasIcon, searchField) {
    return hasIcon ? (searchField ? 'pl-8' : 'pr-8') : '';
};
export var TextAreaField = React.forwardRef(function (_a, forwardedRef) {
    var name = _a.name, placeholder = _a.placeholder, className = _a.className, style = _a.style, disabled = _a.disabled, errorMessage = _a.errorMessage, errors = _a.errors, onChange = _a.onChange, onBlur = _a.onBlur, onFocus = _a.onFocus, fieldProps = __rest(_a, ["name", "placeholder", "className", "style", "disabled", "errorMessage", "errors", "onChange", "onBlur", "onFocus"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    var hasIcon = typeof fieldProps.icon !== 'undefined';
    return (React.createElement(Field, __assign({ name: name, className: className, style: style, errorMessage: determinedErrorMessage }, fieldProps),
        React.createElement(TextArea, { name: name, placeholder: placeholder, disabled: disabled, error: hasError(determinedErrorMessage), ref: forwardedRef, className: getPaddingForField(hasIcon), onChange: onChange, onFocus: onFocus, onBlur: onBlur })));
});
TextAreaField.displayName = 'Form.TextAreaField';
