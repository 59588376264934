import React, { useEffect, useState, useRef } from 'react';
import { DateTime } from 'luxon';
var FOOTER_CLASS = 'bg-primary flex flex-col lg:flex-row lg:h-20';
var FOOTER_CONTENT_CLASS = 'flex h-full bg-primary flex-col flex-grow-0 flex-shrink-0 w-screen px-4 py-2 md:flex-row';
var FOOTER_CONTENT_ABS_CLASS = FOOTER_CONTENT_CLASS + " transform transition-transform duration-200 ease-out md:overflow-y md:fixed md:left-0 md:bottom-0 md:z-30 md:h-20";
var FOOTER_TEXT_CLASS = 'font-body text-white my-auto text-xs md:mr-8';
var FOOTER_LINK_CLASS = 'text-white underline';
var FOOTER_OPTION_CLASS = 'px-1 py-2 inline';
var FOOTER_ICON_LINK_CLASS = 'flex h-auto mt-2 md:my-auto md:ml-auto';
var FOOTER_ICON_CLASS = 'flex mr-auto lg:m-auto h-auto w-32';
export var Standard = function (_a) {
    var footerImageSrc = _a.footerImageSrc, absolute = _a.absolute;
    var _b = useState(true), hidden = _b[0], setHidden = _b[1];
    var _c = useState(0), height = _c[0], setHeight = _c[1];
    var containerEl = useRef(null);
    useEffect(function () {
        if (!window) {
            return;
        }
        var scrollHandler = function () {
            if (window.innerHeight + window.scrollY >=
                document.body.offsetHeight - height) {
                if (hidden) {
                    setHidden(false);
                }
            }
            else {
                if (!hidden) {
                    setHidden(true);
                }
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return function () {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [height, setHidden, hidden]);
    useEffect(function () {
        var _a;
        var newHeight = (_a = containerEl === null || containerEl === void 0 ? void 0 : containerEl.current) === null || _a === void 0 ? void 0 : _a.offsetHeight;
        if (height !== newHeight) {
            setHeight(newHeight !== null && newHeight !== void 0 ? newHeight : 0);
        }
    }, [containerEl, height, setHeight]);
    var year = DateTime.local().toFormat('yyyy');
    return (React.createElement("footer", { className: FOOTER_CLASS },
        React.createElement("div", { className: (absolute ? FOOTER_CONTENT_ABS_CLASS : FOOTER_CONTENT_CLASS) + " " + (hidden ? 'translate-20' : ''), ref: containerEl },
            React.createElement("p", { className: FOOTER_TEXT_CLASS },
                "Copyright ",
                year,
                " Office of the",
                ' ',
                React.createElement("a", { className: FOOTER_LINK_CLASS, href: "http://schoolsup.org/superintendent" }, "Maricopa County School Superintendent"),
                ". All rights reserved.",
                React.createElement("span", { className: FOOTER_OPTION_CLASS },
                    React.createElement("a", { className: FOOTER_LINK_CLASS, href: "https://www.maricopa.gov/124/Site-Terms-Privacy-Policy" }, "Privacy Policy")),
                React.createElement("span", { className: FOOTER_OPTION_CLASS },
                    React.createElement("a", { className: FOOTER_LINK_CLASS, href: "https://www.maricopa.gov/124/Site-Terms-Privacy-Policy" }, "Terms of Use"))),
            React.createElement("a", { href: "http://schoolsup.org/superintendent", className: FOOTER_ICON_LINK_CLASS },
                React.createElement("img", { src: footerImageSrc, className: FOOTER_ICON_CLASS, alt: "" })))));
};
