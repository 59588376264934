import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var ROUND_LINK_STYLES = 'flex justify-center items-center bg-contentAreaBorderSelected text-contentAreaBackground rounded-full w-8 h-8';
var ROUND_LINK_INLINE_STYLES = { flex: '1 1 2rem' };
export var IconLink = function (_a) {
    var color = _a.color, icon = _a.icon, to = _a.to;
    return (React.createElement(Link, { style: ROUND_LINK_INLINE_STYLES, className: ROUND_LINK_STYLES, to: to, color: color },
        React.createElement(FontAwesomeIcon, { icon: icon, size: "1x" })));
};
