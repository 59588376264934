import React from 'react';
import { oftmcssSrc } from '../svg';
var makeHeaderStyle = function (_a) {
    var size = _a.size, inverted = _a.inverted;
    return "font-body font-bold m-1 " + size + " " + (inverted ? 'text-white' : 'text-primary') + " uppercase";
};
export var EpcLogo = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'text-md' : _b, inverted = _a.inverted;
    return (React.createElement("h1", { className: makeHeaderStyle({ size: size, inverted: inverted }) },
        "Educator ",
        React.createElement("span", { className: "text-accent" }, "PRO"),
        " Connect"));
};
export var EpcLogoXL = function () { return (React.createElement("div", { className: "flex font-body bg-primary p-2" },
    React.createElement("img", { src: oftmcssSrc, alt: "", style: { width: '8rem' }, className: "h-auto my-auto mr-2" }),
    React.createElement("h1", { className: "flex flex-col p-0 m-0 font-extrabold leading-none text-lg" },
        React.createElement("p", { className: "text-white" }, "Educator"),
        React.createElement("p", null,
            React.createElement("span", { className: "text-accent" }, "PRO"),
            ' ',
            React.createElement("span", { className: "text-white" }, "Connect"))))); };
export var EpcAdminLogo = function () { return (React.createElement("div", { className: "flex" },
    React.createElement("h1", { className: "flex flex-col my-auto mr-2 p-0 leading-tight " + makeHeaderStyle({
            size: 'xs',
            inverted: true,
        }) },
        React.createElement("small", { className: "flex ml-auto" }, "Educator"),
        React.createElement("small", { className: "flex ml-auto" },
            React.createElement("span", { className: "text-accent mr-1" }, "PRO"),
            " ",
            React.createElement("span", null, "Connect"))),
    React.createElement("h2", { className: makeHeaderStyle({ size: 'text-lg', inverted: true }) },
        React.createElement("span", { className: "text-accent" }, "Admin")))); };
