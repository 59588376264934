import React from 'react';
import { concatStyles } from 'utils';
var PADDED_CONTENT_STYLES = 'px-4 md:px-12 xl:px-32 py-2 md:py-4 lg:py-8 flex flex-col w-full';
var SCROLL_STYLES = 'h-full overflow-y-scroll';
export var PaddedContent = function (_a) {
    var children = _a.children, className = _a.className, scroll = _a.scroll, style = _a.style;
    return (React.createElement("div", { style: style, className: concatStyles([
            PADDED_CONTENT_STYLES,
            scroll ? SCROLL_STYLES : '',
            className,
        ]) }, children));
};
PaddedContent.displayName = 'Layout.PaddedContent';
