import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concatStyles } from 'utils';
var DATE_STYLES = 'flex transition duration-100 ease-out mb-1 ml-1 text-xs';
var DESELECTED_DATE_STYLES = 'bg-gray-100 hover:bg-gray-300';
var SELECTED_DATE_STYLES = 'bg-primary text-white';
var DATE_NAV_STYLES = 'flex bg-white rounded px-2';
var TITLE_STYLES = 'text-sm font-semibold flex-grow text-center m-auto';
var WEEK_STYLES = 'grid grid-cols-7';
var IN_MONTH_STYLES = 'text-gray-800';
var OUT_OF_MONTH_STYLES = 'text-gray-400';
var BUTTON_STYLES = 'p-1';
export var DateSelector = function (_a) {
    var initialDate = _a.initialDate, selectedDate = _a.selectedDate, handleSelectedDate = _a.onDateSelected, _b = _a.dateFormat, dateFormat = _b === void 0 ? 'yyyy-MM-dd' : _b, useFormattedValue = _a.useFormattedValue;
    var _c = useState(0), increment = _c[0], setIncrement = _c[1];
    var handleIncrementClick = function (newIncrement) {
        return function (e) {
            e.preventDefault();
            e.stopPropagation();
            setIncrement(increment + newIncrement);
        };
    };
    useEffect(function () {
        setIncrement(0);
    }, [selectedDate, initialDate]);
    var anchorDate = selectedDate !== null && selectedDate !== void 0 ? selectedDate : initialDate;
    var parsedDate = anchorDate
        ? useFormattedValue
            ? DateTime.fromFormat(anchorDate, dateFormat)
            : DateTime.fromISO(anchorDate)
        : DateTime.fromJSDate(new Date());
    var originDate = parsedDate.isValid
        ? parsedDate
        : DateTime.fromJSDate(new Date());
    var monthStart = originDate.startOf('month').plus({ months: increment });
    var handleDateClick = function (date) { return function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (handleSelectedDate) {
            handleSelectedDate(date);
        }
    }; };
    return (React.createElement("div", { className: "h-full w-full px-1" },
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("nav", { className: DATE_NAV_STYLES },
                React.createElement("button", { onClick: handleIncrementClick(-12), className: BUTTON_STYLES },
                    React.createElement(FontAwesomeIcon, { icon: faChevronDoubleLeft })),
                React.createElement("h3", { className: TITLE_STYLES }, monthStart.toFormat('yyyy')),
                React.createElement("button", { onClick: handleIncrementClick(12), className: BUTTON_STYLES },
                    React.createElement(FontAwesomeIcon, { icon: faChevronDoubleRight }))),
            React.createElement("nav", { className: concatStyles([DATE_NAV_STYLES, 'mb-2']) },
                React.createElement("button", { onClick: handleIncrementClick(-1), className: BUTTON_STYLES },
                    React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
                React.createElement("h3", { className: TITLE_STYLES }, monthStart.toFormat('MMM')),
                React.createElement("button", { onClick: handleIncrementClick(1), className: BUTTON_STYLES },
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight }))),
            new Array(6).fill('').map(function (_, weeks) {
                var start = DateTime.fromISO(monthStart.toISODate())
                    .plus({ weeks: weeks })
                    .startOf('week');
                return (React.createElement("ul", { className: WEEK_STYLES, key: "minical-week-" + weeks }, new Array(7).fill('').map(function (_, days) {
                    var day = DateTime.fromISO(start.toISODate()).plus({ days: days });
                    var inMonth = monthStart.month === day.month;
                    var selected = day.diff(originDate, 'day').days === 0;
                    return (React.createElement("li", { className: concatStyles([
                            DATE_STYLES,
                            selected ? SELECTED_DATE_STYLES : DESELECTED_DATE_STYLES,
                            inMonth ? IN_MONTH_STYLES : OUT_OF_MONTH_STYLES,
                        ]), key: weeks + "-" + days },
                        React.createElement("button", { className: concatStyles([
                                'flex flex-grow p-1',
                                selected ? 'text-white' : null,
                            ]), onClick: handleDateClick(day.toFormat(dateFormat)) },
                            React.createElement("span", { className: "m-auto" }, day.toFormat('d')))));
                })));
            }))));
};
DateSelector.displayName = 'Form.DateSelector';
