var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from '../styled-components';
import { Centered, DecorativeRadio, DecorativeRadioSmall, } from './DecorativeRadio';
export var DecorativeCheckBox = styled(DecorativeRadio)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 0;\n"], ["\n  border-radius: 0;\n"])));
export var DecorativeCheckBoxSmall = styled(DecorativeRadioSmall)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 0;\n  margin: auto 0;\n"], ["\n  border-radius: 0;\n  margin: auto 0;\n"])));
export var DecorativeCheckboxIndicator = function () { return (React.createElement(Centered, null,
    React.createElement(FontAwesomeIcon, { icon: faTimes }))); };
var templateObject_1, templateObject_2;
