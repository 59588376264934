var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from 'polished';
import * as React from 'react';
import DayPicker from 'react-day-picker';
import styled from '../styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n"], ["\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n"])));
var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 0;\n  border: 0;\n  background: transparent;\n  display: flex;\n  cursor: pointer;\n  padding: 0.5rem;\n  flex-shrink: 0;\n  flex-grow: 1;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n"], ["\n  opacity: 0;\n  border: 0;\n  background: transparent;\n  display: flex;\n  cursor: pointer;\n  padding: 0.5rem;\n  flex-shrink: 0;\n  flex-grow: 1;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n"])));
var Dropdown = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 0 ", ";\n  position: absolute;\n  left: 0;\n  top: 100%;\n  z-index: 2;\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 0 ", ";\n  position: absolute;\n  left: 0;\n  top: 100%;\n  z-index: 2;\n"])), function (p) { return p.theme.colorContentAreaBackground; }, function (p) { return p.theme.colorActionable; }, function (p) { return transparentize(0.75)(p.theme.colorActionable); });
var Dismiss = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  background: ", ";\n  height: 100vh;\n  width: 100vw;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  background: ", ";\n  height: 100vh;\n  width: 100vw;\n"])), function (p) { return transparentize(0.9)(p.theme.colorPrimary); });
var useState = React.useState;
export var DateSelect = function (_a) {
    var selectedDate = _a.selectedDate, onSelectDate = _a.onSelectDate, onDismiss = _a.onDismiss, children = _a.children;
    var _b = useState(false), dateFocused = _b[0], setDateFocused = _b[1];
    var toggleDatePicker = function () { return setDateFocused(!dateFocused); };
    var dateSelectHandler = function (date) {
        toggleDatePicker();
        onSelectDate(date);
    };
    var dismissHandler = function (e) {
        if (onDismiss) {
            onDismiss(e);
        }
        toggleDatePicker();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, null,
            React.createElement(Button, { onClick: toggleDatePicker, type: "button" }, "Select Date"),
            children,
            dateFocused ? (React.createElement(Dropdown, null,
                React.createElement(DayPicker, { selectedDays: selectedDate, onDayClick: dateSelectHandler }))) : null),
        dateFocused ? React.createElement(Dismiss, { onClick: dismissHandler }) : null));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
