var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import React, { useState } from 'react';
import { Simple } from '../Avatar';
import * as Navigation from '../Navigation';
import { theme } from '../theme';
import { concatStyles, truncate } from 'utils';
import { Name } from './Name';
import { Subtitle } from './Subtitle';
import { Body } from './Body';
import { View } from './View';
var ICON_GROUP_STYLE = 'flex absolute ml-2 mr-2 md:ml-3 md:mr-3 z-10';
var PROFILE_CARD_STYLE = 'h-auto sm:h-48 md:h-56 lg:h-64 text-center';
export var Profile = function (_a) {
    var _b;
    var favorite = _a.favorite, profile = _a.profile, onSelectProfile = _a.onSelectProfile, handleFavorite = _a.onFavoriteProfile, renderAccessory = _a.renderAccessory, className = _a.className;
    var _c = useState(false), favoriting = _c[0], setFavoriting = _c[1];
    var onSelect = function () {
        if (onSelectProfile) {
            onSelectProfile(profile.id);
        }
    };
    var handleFavoriteClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!handleFavorite) return [3 /*break*/, 2];
                    setFavoriting(true);
                    return [4 /*yield*/, handleFavorite(profile.id, !favorite)];
                case 1:
                    _a.sent();
                    setFavoriting(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleMessageClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (onSelectProfile) {
                onSelectProfile(profile.id, { messages: true });
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(View, { className: concatStyles([PROFILE_CARD_STYLE, className]), onClick: onSelect },
        React.createElement("div", { className: "flex flex-col my-auto flex-grow" },
            React.createElement("div", { className: ICON_GROUP_STYLE, style: {
                    right: 'auto',
                    left: 0,
                } }, renderAccessory && renderAccessory()),
            React.createElement("div", { className: ICON_GROUP_STYLE, style: {
                    left: 'auto',
                    right: 0,
                } },
                profile.hasUnreadMessages ? (React.createElement("div", { className: "mb-1" },
                    React.createElement(Navigation.IconButton, { color: theme.colorFavorite, icon: faComment, onClick: handleMessageClick }))) : null,
                React.createElement(Navigation.IconButton, { icon: faStar, onClick: handleFavoriteClick, color: favorite ? theme.colorFavorite : null, loading: favoriting })),
            React.createElement("div", { className: "mx-auto mb-2" },
                React.createElement(Simple, { color: (_b = profile === null || profile === void 0 ? void 0 : profile.color) !== null && _b !== void 0 ? _b : undefined, name: profile.name, maxInitials: 2, imageUrl: profile.avatarUrl, size: 100 })),
            React.createElement(Name, null, profile.name),
            React.createElement(Subtitle, null, profile.title),
            React.createElement(Body, null, truncate(profile.about, 81)))));
};
Profile.displayName = 'Card.Profile';
