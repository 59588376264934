import React from 'react';
import { concatStyles } from 'utils';
var BORDER_STYLES = 'border-b border-solid border-contentAreaBorder';
var NO_BORDER_STYLES = 'border-b-0';
var HEADER_SECTION_STYLES = 'uppercase text-subheading font-heading text-sm pb-2 font-semibold';
export var HeaderSection = function (_a) {
    var _b = _a.border, border = _b === void 0 ? false : _b, children = _a.children;
    return (React.createElement("div", { className: concatStyles([
            HEADER_SECTION_STYLES,
            border ? BORDER_STYLES : NO_BORDER_STYLES,
        ]) }, children));
};
HeaderSection.displayName = 'Content.HeaderSection';
