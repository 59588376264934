var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ErrorMessage, Field as FormikField } from 'formik';
import * as React from 'react';
import { Descriptive, Label, RequiredIcon } from '../Form';
import { Autocomplete } from './Autocomplete';
import { Error } from './Error';
import { FieldContainer, InputWrapper } from './FieldContainer';
import { Icon } from './Icon';
import { Select } from './Select';
import { Masked, Text } from './Text';
import { TextArea } from './TextArea';
export var Masks = {
    PhoneNumber: [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ],
};
export var RendererComponent;
(function (RendererComponent) {
    RendererComponent[RendererComponent["Text"] = 0] = "Text";
    RendererComponent[RendererComponent["TextArea"] = 1] = "TextArea";
    RendererComponent[RendererComponent["Select"] = 2] = "Select";
    RendererComponent[RendererComponent["Masked"] = 3] = "Masked";
})(RendererComponent || (RendererComponent = {}));
var useState = React.useState;
/**
 * Generates a field component with a label, optional description, and
 * Formik mapped input of the supplied component type.
 * @see RendererComponent
 * @see IRendererProps
 * @param props The supplied configuration for the component.
 *
 */
export function Renderer() {
    var GeneratedRendererComponent = function (_a) {
        var component = _a.component, disabled = _a.disabled, description = _a.description, name = _a.name, placeholder = _a.placeholder, required = _a.required, height = _a.height, icon = _a.icon, label = _a.label, children = _a.children, customAutocompleteRenderer = _a.customAutocompleteRenderer, handleAutocomplete = _a.handleAutocomplete, handleAutocompleteSelection = _a.handleAutocompleteSelection, type = _a.type, wrapperProps = __rest(_a, ["component", "disabled", "description", "name", "placeholder", "required", "height", "icon", "label", "children", "customAutocompleteRenderer", "handleAutocomplete", "handleAutocompleteSelection", "type"]);
        var _b = useState(false), focused = _b[0], setFocused = _b[1];
        var renderComponent = function (props, componentType) {
            var _a = props.field, value = _a.value, f = __rest(_a, ["value"]);
            switch (componentType) {
                case RendererComponent.Select:
                    return (React.createElement(Select, __assign({}, f, { value: value }), children));
                case RendererComponent.TextArea:
                    return (React.createElement(TextArea, __assign({}, f, { value: value, placeholder: placeholder, minHeight: height })));
                case RendererComponent.Masked:
                    return (React.createElement(Masked, __assign({}, f, { value: value, mask: wrapperProps.mask || [], id: name, placeholder: placeholder, guide: wrapperProps.guide || false })));
                default:
                    var onFocus = handleAutocomplete ||
                        customAutocompleteRenderer ||
                        wrapperProps.onFocus
                        ? function (e) {
                            setFocused(true);
                            if (wrapperProps.onFocus) {
                                wrapperProps.onFocus(e);
                            }
                        }
                        : undefined;
                    var onBlur = handleAutocomplete ||
                        customAutocompleteRenderer ||
                        wrapperProps.onBlur
                        ? function (e) {
                            setTimeout(function () {
                                setFocused(false);
                                if (wrapperProps.onBlur) {
                                    wrapperProps.onBlur(e);
                                }
                            }, 150);
                            f.onBlur(e);
                        }
                        : f.onBlur;
                    return (React.createElement(InputWrapper, null,
                        React.createElement(Text, __assign({}, f, { value: value || '', autoComplete: "off", disabled: disabled, placeholder: placeholder, type: type || 'text', onBlur: onBlur, onFocus: onFocus })),
                        icon ? React.createElement(Icon, { icon: icon }) : React.createElement("span", null)));
            }
        };
        var renderField = function (props) { return (React.createElement(FieldContainer, { disabled: disabled, my: wrapperProps.my || 2, mx: wrapperProps.mx || 0 },
            label && (React.createElement(Label, { htmlFor: name },
                label,
                required && React.createElement(RequiredIcon, null))),
            description ? React.createElement(Descriptive, null, description) : null,
            renderComponent(props, component),
            React.createElement(ErrorMessage, { name: wrapperProps.nameForError || name, component: Error }),
            handleAutocomplete && focused ? (React.createElement(Autocomplete, { suggestions: handleAutocomplete(), onSelect: handleAutocompleteSelection })) : null,
            customAutocompleteRenderer && focused
                ? customAutocompleteRenderer()
                : null)); };
        return React.createElement(FormikField, { name: name, render: renderField });
    };
    return GeneratedRendererComponent;
}
