import React from 'react';
import { concatStyles } from 'utils';
var INACTIVE_CONTENT_STYLES = 'flex text-blankSlateInactiveContent text-center font-body';
export var InactiveContent = function (_a) {
    var _b = _a.flexDirection, flexDirection = _b === void 0 ? 'row' : _b, children = _a.children, className = _a.className;
    var direction = flexDirection === 'row' ? 'flex-row' : 'flex-col';
    var padding = flexDirection === 'row' ? 'py-2 px-4' : 'p-8';
    return (React.createElement("div", { className: concatStyles([
            INACTIVE_CONTENT_STYLES,
            direction,
            padding,
            className,
        ]) }, children));
};
InactiveContent.displayName = 'Content.InactiveContent';
