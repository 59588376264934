import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { Content, Input } from '..';
var CONTAINER_STYLES = 'flex flex-col box-border m-0';
var FACETS_CONTAINER_STYLES = 'flex flex-row flex-wrap box-border m-0';
var ICON_CONTAINER_STYLES = 'my-auto ml-2 box-border m-0';
export var SearchControls = function (_a) {
    var facets = _a.facets, handleRemoveFacet = _a.onRemoveFacet, queryString = _a.queryString, handleQuery = _a.onQuery;
    var handleTextChange = function (e) {
        handleQuery(e.currentTarget.value);
    };
    var handleClearFilter = function (id) { return function (e) {
        handleRemoveFacet(id);
    }; };
    return (React.createElement("div", { className: CONTAINER_STYLES },
        React.createElement(Input.Text, { placeholder: "Search keywords, name", value: queryString, onChange: handleTextChange }),
        facets ? (React.createElement("div", { className: "mt-2" },
            facets.length > 0 ? React.createElement(Content.Text, null, "Filters:") : null,
            React.createElement("div", { className: FACETS_CONTAINER_STYLES }, facets.map(function (f) { return (React.createElement("button", { className: "mt-2", key: f.id, onClick: handleClearFilter(f.id) },
                React.createElement(Content.Bubble, null,
                    f.value,
                    React.createElement("div", { className: ICON_CONTAINER_STYLES },
                        React.createElement(FontAwesomeIcon, { icon: faTimes }))))); })))) : null));
};
