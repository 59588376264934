import React from 'react';
import { concatStyles } from 'utils';
var HEADING_SMALL_STYLES = 'text-base mt-8 mr-0 mb-2 ml-0 font-heading';
var FONT_WEIGHT_SEMI_BOLD = 'font-semibold';
var FONT_HAIR_LINE = 'font-hairline';
export var HeadingSmall = function (_a) {
    var _b = _a.bold, bold = _b === void 0 ? false : _b, textTransform = _a.textTransform, margin = _a.margin, className = _a.className, children = _a.children;
    return (React.createElement("h2", { style: { textTransform: textTransform !== null && textTransform !== void 0 ? textTransform : 'uppercase', margin: margin }, className: concatStyles([
            HEADING_SMALL_STYLES,
            bold ? FONT_WEIGHT_SEMI_BOLD : FONT_HAIR_LINE,
            className,
        ]) }, children));
};
HeadingSmall.displayName = 'Content.HeadingSmall';
