import React from 'react';
import { concatStyles } from 'utils';
var INDICATOR_STYLES = 'flex mx-auto mb-2 rounded-full h-12 w-12';
var STEP_ID_STYLES = 'text-white text-xl font-body m-auto';
var STEP_LABEL_STYLES = 'font-body text-xs mx-auto';
var getColor = function (_a) {
    var disabled = _a.disabled, active = _a.active;
    if (disabled) {
        return 'bg-contentAreaBorder';
    }
    else if (active) {
        return 'bg-primary';
    }
    return 'bg-secondary';
};
export var Step = function (_a) {
    var step = _a.step, label = _a.label, disabled = _a.disabled, active = _a.active;
    return (React.createElement("li", { className: "flex flex-col mx-2" },
        React.createElement("div", { className: concatStyles([
                INDICATOR_STYLES,
                getColor({ disabled: disabled, active: active }),
            ]) },
            React.createElement("p", { className: STEP_ID_STYLES }, step)),
        React.createElement("p", { className: STEP_LABEL_STYLES }, label)));
};
