import React from 'react';
export var NavigatorContext = React.createContext({
    compact: false,
    theme: 'primary',
});
export var NavigatorItemContext = React.createContext({
    active: false,
    hovering: false,
    theme: 'primary',
});
