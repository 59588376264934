import React from 'react';
import * as Content from '../Content';
var CONTAINER_STYLES = 'flex w-full m-0 box-border';
var PAGINATION_WRAPPER_STYLES = 'flex box-border p-2 m-0 mx-auto my-0';
var PAGE_STYLES = 'p-2 m-0 box-border';
export var PaginationBar = function (_a) {
    var currentPage = _a.currentPage, nextPages = _a.nextPages, previousPages = _a.previousPages, onPageClick = _a.onPageClick;
    var handlePageClick = function (_a) {
        var page = _a.page, cursor = _a.cursor;
        return function (e) {
            e.preventDefault();
            onPageClick({ page: page, cursor: cursor });
        };
    };
    if ((nextPages || []).length + (previousPages || []).length < 1) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Content.Text, null,
        React.createElement("div", { className: CONTAINER_STYLES },
            React.createElement("ul", { className: PAGINATION_WRAPPER_STYLES },
                previousPages && previousPages.length > 0
                    ? previousPages
                        .map(function (p) { return p; })
                        .map(function (_a, i) {
                        var cursor = _a.cursor;
                        var page = currentPage - previousPages.length + i;
                        return (React.createElement("li", { className: PAGE_STYLES, key: cursor },
                            React.createElement("button", { className: "text-actionable", onClick: handlePageClick({ page: page, cursor: cursor }) }, page)));
                    })
                    : null,
                React.createElement("li", { className: PAGE_STYLES }, currentPage),
                nextPages && nextPages.length > 0
                    ? nextPages
                        .map(function (p) { return p; })
                        .map(function (_a, i) {
                        var cursor = _a.cursor;
                        return (React.createElement("li", { className: PAGE_STYLES, key: cursor },
                            React.createElement("button", { className: "text-actionable", onClick: handlePageClick({
                                    cursor: cursor,
                                    page: currentPage + i + 1,
                                }) }, currentPage + i + 1)));
                    })
                    : null))));
};
