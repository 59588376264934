import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { concatStyles } from 'utils';
import { Label } from './Label';
import { RequiredIcon } from './RequiredIcon';
import { Error } from './Error';
export var getErrorMessage = function (_a) {
    var _b;
    var name = _a.name, errorMessage = _a.errorMessage, errors = _a.errors;
    return errorMessage !== null && errorMessage !== void 0 ? errorMessage : (_b = (errors !== null && errors !== void 0 ? errors : {})[name]) === null || _b === void 0 ? void 0 : _b.message;
};
var CONTAINER_STYLES = 'flex flex-col';
var ICON_STYLES = 'absolute inset-y-0 flex text-contentAreaBorder';
var WRAPPER_STYLES = 'relative flex flex-grow';
var getGrowStyle = function (grow) { return (grow ? 'flex-grow' : ''); };
var getDisabledStyle = function (disabled) { return (disabled ? 'opacity-75' : ''); };
var getIconPositionIf = function (searchField) {
    return searchField ? 'left-0 ml-3' : 'right-0 mr-3';
};
export var Field = function (_a) {
    var label = _a.label, name = _a.name, className = _a.className, required = _a.required, icon = _a.icon, errorMessage = _a.errorMessage, grow = _a.grow, children = _a.children, disabled = _a.disabled, searchField = _a.searchField;
    return (React.createElement("div", { className: concatStyles([
            CONTAINER_STYLES,
            getGrowStyle(grow),
            getDisabledStyle(disabled),
            className,
        ]) },
        React.createElement(Label, { htmlFor: name },
            label,
            " ",
            required && React.createElement(RequiredIcon, null)),
        React.createElement("div", { className: WRAPPER_STYLES },
            children,
            icon ? (React.createElement("span", { className: concatStyles([
                    ICON_STYLES,
                    getIconPositionIf(searchField),
                ]) },
                React.createElement(FontAwesomeIcon, { icon: icon, className: "m-auto" }))) : (React.createElement("span", null)),
            errorMessage ? React.createElement(Error, null, errorMessage) : null)));
};
Field.displayName = 'Form.Field';
