var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import * as Avatar from '../Avatar';
import { truncate } from 'utils';
import { Title } from './Title';
import { Body } from './Body';
import { View } from './View';
export var Result = function (_a) {
    var maxInitials = _a.maxInitials, title = _a.title, subtitle = _a.subtitle, iconColor = _a.iconColor, iconUrl = _a.iconUrl, truncateLimit = _a.truncate, view = __rest(_a, ["maxInitials", "title", "subtitle", "iconColor", "iconUrl", "truncate"]);
    return (React.createElement(View, __assign({}, view),
        React.createElement("div", { className: "mr-2" },
            React.createElement(Avatar.Simple, { color: iconColor || '', name: title, maxInitials: maxInitials || 2, imageUrl: iconUrl !== null && iconUrl !== void 0 ? iconUrl : '', size: 24 })),
        React.createElement("div", { className: "flex flex-col my-auto" },
            React.createElement(Title, null, truncate(title, truncateLimit)),
            React.createElement(Body, null, truncate(subtitle !== null && subtitle !== void 0 ? subtitle : '', truncateLimit)))));
};
Result.displayName = 'Card.Result';
