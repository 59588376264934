var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { concatStyles } from 'utils';
var LIST_STYLE = 'flex flex-col flex-grow';
var TOP_LEVEL_LIST_STYLE = 'max-w-full';
var NESTED_LIST_STYLE = '-m-2 sm:flex-row';
var NESTED_COL_STYLE = '-m-2';
var ITEM_STYLE = 'flex flex-grow p-2';
var RESPONSIVE_TWO_COL = 'sm:w-1/2';
var RESPONSIVE_THREE_COL = 'sm:w-1/3';
var RESPONSIVE_GROWTH_STYLE = 'sm:flex-grow-0';
export var Fields = function (_a) {
    var className = _a.className, children = _a.children, nested = _a.nested, strictVertical = _a.strictVertical, grow = _a.grow, register = _a.register, errors = _a.errors, columnWidths = _a.columnWidths;
    var childCount = React.Children.toArray(children).filter(function (c) { var _a; return c && ((_a = c === null || c === void 0 ? void 0 : c.props) === null || _a === void 0 ? void 0 : _a.type) !== 'hidden'; }).length;
    return (React.createElement("ul", { className: concatStyles([
            LIST_STYLE,
            nested
                ? strictVertical
                    ? NESTED_COL_STYLE
                    : NESTED_LIST_STYLE
                : TOP_LEVEL_LIST_STYLE,
            grow && RESPONSIVE_GROWTH_STYLE,
            className,
        ]) }, React.Children.map(children, function (child, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (!child) {
            return;
        }
        var classNames = columnWidths
            ? concatStyles([columnWidths[index], ITEM_STYLE])
            : concatStyles([
                ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.type) === 'hidden' ? 'w-0 h-0' : ITEM_STYLE,
                ((_b = child === null || child === void 0 ? void 0 : child.props) === null || _b === void 0 ? void 0 : _b.type) !== 'hidden' &&
                    grow &&
                    !strictVertical &&
                    childCount === 3 &&
                    RESPONSIVE_THREE_COL,
                ((_c = child === null || child === void 0 ? void 0 : child.props) === null || _c === void 0 ? void 0 : _c.type) !== 'hidden' &&
                    grow &&
                    !strictVertical &&
                    childCount === 2 &&
                    RESPONSIVE_TWO_COL,
                ((_d = child === null || child === void 0 ? void 0 : child.props) === null || _d === void 0 ? void 0 : _d.type) !== 'hidden' &&
                    grow &&
                    (strictVertical || childCount > 3 || childCount < 2) &&
                    RESPONSIVE_GROWTH_STYLE,
            ]);
        return (React.createElement("li", { className: classNames }, ((_e = child === null || child === void 0 ? void 0 : child.props) === null || _e === void 0 ? void 0 : _e.name) || ((_f = child === null || child === void 0 ? void 0 : child.props) === null || _f === void 0 ? void 0 : _f.nested)
            ? React.createElement(child.type, __assign({}, __assign(__assign({}, child.props), { register: register, ref: ((_g = child === null || child === void 0 ? void 0 : child.props) === null || _g === void 0 ? void 0 : _g.name) ? register : null, errors: errors, key: "child" + child.type + index + ((_j = (_h = child === null || child === void 0 ? void 0 : child.props) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : '') })))
            : React.createElement(child.type, __assign({}, __assign(__assign({}, child.props), { key: "child" + child.type + index })))));
    })));
};
Fields.displayName = 'Form.Fields';
