var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { useElementRect } from './useElementRect';
import { useImageSize } from './useImageSize';
import { useRedactions } from './useRedactions';
import { concatStyles } from 'utils';
import { ActionButton } from '../Content';
import { blobToFile } from 'utils';
import { AbsoluteOverlay } from '../Indicators';
import { ButtonGroup } from '../Form';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
var ERROR_CLASS = 'flex flex-col p-4 bg-error bg-opacity-25 border border-error text-error rounded font-body font-bold text-center';
export var RedactionView = function (_a) {
    var crossOriginImage = _a.crossOriginImage, className = _a.className, style = _a.style, initialRedactions = _a.initialRedactions, image = _a.image, _b = _a.filename, filename = _b === void 0 ? new Date().getTime() + ".jpg" : _b, onSave = _a.onSave, onCancel = _a.onCancel, externalLoading = _a.loading, _c = _a.minHeight, minHeight = _c === void 0 ? 100 : _c, editable = _a.editable, _d = _a.cancelIcon, cancelIcon = _d === void 0 ? faTimes : _d, _e = _a.cancelLabel, cancelLabel = _e === void 0 ? 'Cancel' : _e, _f = _a.saveIcon, saveIcon = _f === void 0 ? faCheck : _f, _g = _a.saveLabel, saveLabel = _g === void 0 ? 'Save Changes' : _g, documentClassname = _a.documentClassname, noScale = _a.noScale, onRetry = _a.onRetry, _h = _a.autoRetry, autoRetry = _h === void 0 ? 0 : _h;
    var _j = useElementRect(), containerEl = _j.ref, containerRect = _j.rect;
    var _k = useImageSize({
        src: image,
        crossOrigin: crossOriginImage,
        autoRetry: autoRetry,
        onRetry: onRetry,
    }), size = _k.size, error = _k.error, img = _k.img, loadingImage = _k.loading;
    var _l = useState(null), drawError = _l[0], setDrawingError = _l[1];
    var _m = useState(1), scale = _m[0], setScale = _m[1];
    var imageRef = useRef(null);
    var _o = useRedactions({
        editable: editable,
        initialRedactions: initialRedactions,
        externalScale: scale,
    }), redactionsSurfaceRef = _o.ref, generateCaptureWithImage = _o.generateCaptureWithImage, reset = _o.reset, domEventHandlers = _o.domEventHandlers;
    var _p = useState(image), lastImage = _p[0], setLastImage = _p[1];
    useEffect(function () {
        if (image !== lastImage) {
            reset();
            setLastImage(image);
        }
    }, [image, lastImage, setLastImage, reset]);
    useEffect(function () {
        var _a;
        (_a = containerEl.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    }, [containerEl]);
    useEffect(function () {
        var _a;
        var el = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current;
        var ctx = el === null || el === void 0 ? void 0 : el.getContext('2d');
        if (!el || !ctx) {
            return;
        }
        try {
            if (img) {
                ctx.drawImage(img, 0, 0);
                ctx.drawImage(img, 0, 0, img.width, img.height);
                setScale(noScale ? 1 : ((_a = containerRect === null || containerRect === void 0 ? void 0 : containerRect.width) !== null && _a !== void 0 ? _a : 1) / img.width);
            }
        }
        catch (e) {
            setDrawingError(e.message);
        }
    }, [size, containerRect, img, noScale]);
    var scaleStyle = {
        transform: "scale(" + scale + ")",
        transformOrigin: 'top left',
    };
    var getFile = function () {
        if (!img) {
            return;
        }
        var imageUrl = generateCaptureWithImage(img);
        if (!imageUrl) {
            return;
        }
        var binary = atob(imageUrl.split(',')[1]);
        var data = Array(binary.length)
            .fill('')
            .map(function (_, i) { return binary.charCodeAt(i); });
        var blob = new Blob([new Uint8Array(data)], { type: 'image/jpeg' });
        return blobToFile(blob, filename);
    };
    var handleSave = function () {
        if (!img || !onSave) {
            return;
        }
        onSave(getFile());
    };
    var handleCancel = function () {
        if (!img || !onCancel) {
            return;
        }
        reset();
        onCancel(getFile());
    };
    var calculatedHeight = size.height * scale;
    var calculatedWidth = size.width * scale;
    var loading = loadingImage || externalLoading;
    return (React.createElement("div", { className: concatStyles('flex flex-col', className), style: style, ref: containerEl },
        error || drawError ? (React.createElement("div", { className: ERROR_CLASS },
            React.createElement("span", { className: "mb-2" }, error),
            onRetry ? (React.createElement(ActionButton, { label: "Reload", icon: faSync, appearance: "destructive", align: "center", onClick: onRetry })) : null)) : (React.createElement("article", { className: concatStyles('relative w-full box-content', noScale && 'overflow-x-auto', documentClassname) },
            React.createElement("div", { className: concatStyles('w-full'), style: {
                    width: isNaN(calculatedWidth) || calculatedWidth === Infinity
                        ? undefined
                        : calculatedWidth,
                } },
                React.createElement("canvas", __assign({ ref: redactionsSurfaceRef }, domEventHandlers, size, { className: "absolute top-0 left-0 z-10", style: scaleStyle })),
                React.createElement("canvas", __assign({ ref: imageRef }, size, { style: scaleStyle, className: "absolute z-0" })),
                React.createElement("div", { style: {
                        height: isNaN(calculatedHeight) || calculatedHeight === Infinity
                            ? minHeight
                            : calculatedHeight,
                    }, className: "w-full" })))),
        editable ? (React.createElement(ButtonGroup, { direction: "row", className: "ml-auto mt-2" },
            onCancel ? (React.createElement(ActionButton, { label: cancelLabel, onClick: handleCancel, icon: cancelIcon, appearance: "destructive" })) : null,
            onSave ? (React.createElement(ActionButton, { label: saveLabel, onClick: handleSave, icon: saveIcon })) : null)) : null,
        loading ? React.createElement(AbsoluteOverlay, null) : null));
};
