export var refreshCurrentAccessToken = function (apiUrl, token) {
    return fetch(apiUrl + "/auth/token", {
        body: JSON.stringify({ refreshToken: token, grantType: 'refresh' }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function (response) {
        return response.json();
    })
        .then(function (_a) {
        var _b = _a.data, accessToken = _b.accessToken, refreshToken = _b.refreshToken, errors = _a.errors;
        return {
            accessToken: accessToken,
            errors: errors,
            refreshToken: refreshToken,
        };
    });
};
