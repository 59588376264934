import React from 'react';
import { concatStyles } from 'utils';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessoryLabel } from './AccessoryLabel';
var ACCESSORY_STYLES = 'flex flex-row m-0 box-border min-w-0 max-w-full flex-grow';
var BUTTON_STYLES = 'mt-4 text-xl ml-auto w-8 h-8';
/**
 * An accessory adjacent to any content item contents.
 * @param props Configuration for the item.
 */
export var AccessoryView = function (_a) {
    var label = _a.label, className = _a.className, _b = _a.icon, icon = _b === void 0 ? faChevronRight : _b, handleOnClick = _a.onClick;
    return (React.createElement("div", { className: concatStyles([ACCESSORY_STYLES, className]) },
        React.createElement(AccessoryLabel, null, label),
        React.createElement("button", { className: BUTTON_STYLES, onClick: handleOnClick },
            React.createElement(FontAwesomeIcon, { icon: icon }))));
};
