import React from 'react';
import { concatStyles } from 'utils';
var DIVIDER_STYLES = 'flex bg-contentAreaBorder';
var VERTICAL_STYLES = 'w-px';
var HORIZONTAL_STYLES = 'h-px';
var FLEX = { flex: '0 0 1px' };
export var Divider = function (_a) {
    var _b = _a.vertical, vertical = _b === void 0 ? false : _b, className = _a.className;
    return (React.createElement("div", { style: FLEX, className: concatStyles([
            DIVIDER_STYLES,
            vertical ? VERTICAL_STYLES : HORIZONTAL_STYLES,
            className,
        ]) }));
};
Divider.displayName = 'Content.Divider';
