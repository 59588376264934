import jwtDecode from 'jwt-decode';
import { isEmpty } from 'validator';
import { AccessLevel } from './types';
import { getAccessToken } from './getAccessToken';
import { getBearerToken } from './getBearerToken';
export var getCurrentAccessLevel = function () {
    var accessToken = getAccessToken();
    return isEmpty(accessToken || '')
        ? AccessLevel.User
        : jwtDecode(accessToken).accessLevel;
};
export var getCurrentUserId = function () {
    var accessToken = getAccessToken();
    return isEmpty(accessToken || '')
        ? ''
        : jwtDecode(accessToken).userId;
};
export var getCurrentEmailAddress = function () {
    var accessToken = getBearerToken();
    return isEmpty(accessToken || '')
        ? ''
        : jwtDecode(accessToken).emailAddress;
};
export var accessTokenPresent = function () {
    return !isEmpty(getAccessToken() || '');
};
