var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFormikContext } from 'formik';
import * as React from 'react';
import { DecorativeRadio, DecorativeRadioIndicator, DecorativeRadioSmall, } from '../Form';
import { FieldGroupAppearanceSize, FieldGroupRenderer, } from './FieldGroupFieldRenderer';
var defaultContainerForAppearance = function (appearance) {
    switch (appearance) {
        case FieldGroupAppearanceSize.Small:
            return DecorativeRadioSmall;
        default:
            return DecorativeRadio;
    }
};
export function RadioGroupRenderer() {
    var Renderer = FieldGroupRenderer();
    var GeneratedRenderer = function (props) {
        var form = useFormikContext();
        var onChange = function (_a) {
            var name = _a.name, value = _a.value;
            form.setFieldValue(name, value);
        };
        var isChecked = function (_a) {
            var value = _a.value, fieldValue = _a.fieldValue;
            return value === fieldValue;
        };
        return (React.createElement(Renderer, __assign({}, props, { inputType: "radio", onChange: onChange, isChecked: isChecked, container: props.container ||
                defaultContainerForAppearance(props.appearanceSize || FieldGroupAppearanceSize.Default), indicator: props.indicator || DecorativeRadioIndicator })));
    };
    return GeneratedRenderer;
}
