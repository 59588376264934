var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useField } from 'formik';
import React from 'react';
import styled, { remCalc } from '../styled-components';
export var TextArea = styled.textarea(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: 200;\n  padding: 8px;\n  flex-grow: 1;\n  width: auto;\n  min-height: ", ";\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n  border-radius: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: 200;\n  padding: 8px;\n  flex-grow: 1;\n  width: auto;\n  min-height: ", ";\n"])), function (p) { return p.theme.colorContentAreaBorder; }, function (p) { return p.theme.borderRadius; }, function (p) { return p.theme.fontFamilyBody; }, remCalc(13), function (p) { return p.minHeight || '4em'; });
export var TextAreaField = function (_a) {
    var children = _a.children, minHeight = _a.minHeight, input = __rest(_a, ["children", "minHeight"]);
    var _b = useField(input)[0], onBlur = _b.onBlur, onChange = _b.onChange, value = _b.value;
    return (React.createElement(TextArea, __assign({}, input, { onBlur: onBlur, onChange: onChange, minHeight: minHeight, value: value })));
};
var templateObject_1;
