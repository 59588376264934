import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Card from '../../Card';
import * as Content from '../../Content';
import * as Form from '../../Form';
import * as Navigation from '../../Navigation';
import * as Responsive from '../../Responsive';
import { PaddedContent } from '../../Layout';
/**
 * Renders a summary of an error so that a user can contact
 * support with more details or get back to the previous
 * view.
 */
export var ErrorView = function (_a) {
    var error = _a.error;
    // Drawer appearance
    var useDisplayMode = useContext(Responsive.DrawerManagerContext).useDisplayMode;
    useDisplayMode('expanded');
    var history = useHistory();
    var goBack = function () { return history.goBack(); };
    return (React.createElement(PaddedContent, null,
        React.createElement(Content.Body, null,
            React.createElement(Navigation.ReturnLink, { to: "#", onClick: goBack }, "Go Back")),
        React.createElement(Content.Heading, null, "An Unexpected Error Has Occurred"),
        React.createElement(Content.Body, null, "Please notify our support team of this issue so that we can further address it if necessary."),
        React.createElement(Form.Divider, null),
        React.createElement(Card.View, null,
            React.createElement("div", { className: "flex flex-col m-0 box-border p-2 max-w-full overflow-x-auto" },
                React.createElement(Content.Code, { style: { overflowX: 'auto' } }, error !== null && error !== void 0 ? error : 'Unknown error')))));
};
