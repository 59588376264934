import { useEffect, useState, useRef } from 'react';
import { useWindowSize } from 'utils';
export var useElementRect = function () {
    var windowSize = useWindowSize();
    var _a = useState(null), inlineSize = _a[0], setInlineSize = _a[1];
    var ref = useRef(null);
    var _b = useState(null), rect = _b[0], setRect = _b[1];
    useEffect(function () {
        var _a, _b;
        setRect((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) !== null && _b !== void 0 ? _b : null);
    }, [ref, windowSize, inlineSize]);
    useEffect(function () {
        var resizeObserver = new ResizeObserver(function (entries) {
            var entry = entries[0];
            var size = entry === null || entry === void 0 ? void 0 : entry.contentRect.width;
            if (size !== inlineSize) {
                setInlineSize(size);
            }
        });
        resizeObserver.observe(ref.current);
        return function () { return resizeObserver.disconnect(); };
    });
    return { ref: ref, rect: rect };
};
