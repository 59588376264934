import React from 'react';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { faFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage';
import { Sortable } from '../../Behavior';
import { ItemTypes } from './ItemTypes';
import { Sendable } from './Sendable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Content from '../../Content';
var ATTACHMENT_STYLES = 'flex flex-grow m-1 relative bg-gray-300 bg-opacity-25';
var PREVIEW_WRAPPER_STYLES = 'flex box-border m-0 w-full p-2';
var ICON_STYLES = 'box-border m-0';
var TEXT_CONTENT_STYLES = 'box-border m-0 w-full pl-4 my-auto';
var Icon = function (_a) {
    var fileId = _a.fileId;
    var extension = fileId.split('.').reverse()[0];
    var icon = faFile;
    switch (extension) {
        case 'pdf':
            icon = faFilePdf;
            break;
        case 'xls':
            icon = faFileExcel;
            break;
        case 'xlsx':
            icon = faFileExcel;
            break;
        case 'ppt':
            icon = faFilePowerpoint;
            break;
        case 'doc':
            icon = faFileWord;
            break;
        case 'docx':
            icon = faFileWord;
            break;
        case 'csv':
            icon = faFileCsv;
            break;
        case 'jpg':
            icon = faFileImage;
            break;
        case 'jpeg':
            icon = faFileImage;
            break;
        case 'png':
            icon = faFileImage;
            break;
        case 'gif':
            icon = faFileImage;
            break;
    }
    return React.createElement(FontAwesomeIcon, { icon: icon, size: "2x" });
};
export var AttachmentPreview = function (_a) {
    var id = _a.id, canDrop = _a.canDrop, preview = _a.preview, progress = _a.progress, onSort = _a.onSort, onFinishSort = _a.onFinishSort, onDelete = _a.onDelete, index = _a.index;
    return (React.createElement(Sortable, { id: id, index: index, type: ItemTypes.File, onSort: onSort, onFinishSort: onFinishSort, sortDirection: "vertical" },
        React.createElement("div", { className: ATTACHMENT_STYLES },
            React.createElement(Sendable, { onDelete: onDelete, deletable: canDrop, progress: progress, preview: preview, listMode: true }, preview ? (React.createElement("div", { className: PREVIEW_WRAPPER_STYLES },
                React.createElement("div", { className: ICON_STYLES },
                    React.createElement(Icon, { fileId: preview.id })),
                React.createElement("div", { className: TEXT_CONTENT_STYLES },
                    React.createElement(Content.Text, null, preview.name)))) : null))));
};
