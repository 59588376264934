import React, { useState } from 'react';
import * as Content from '../Content';
import * as Form from '../Form';
import * as Input from '../Input';
import { View } from './View';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStar as faStarOutline } from '@fortawesome/pro-light-svg-icons/faStar';
import { concatStyles } from 'utils';
var FLEX_COLUMN = 'flex flex-col';
var FLEX_CONTAINER_STYLES = FLEX_COLUMN + " p-8 pt-0";
var RATING_CONTAINER_STYLES = 'flex mb-4';
var ACTIVE_RATING_BACKGROUND_STYLES = 'bg-accent';
var DEFAULT_RATING_BACKGROUND_STYLES = 'bg-contentAreaBackground';
var ACTIVE_RATING_COLOR_STYLES = 'text-contentAreaBackground';
var DEFAULT_RATING_COLOR_STYLES = 'text-accent';
var RATING_STYLES = 'flex items-center text-xl mr-1 w-10 h-10 border border-solid border-accent rounded-full';
var COMMENT_STYLES = FLEX_COLUMN + " mb-2 w-full";
export var Rating = function (_a) {
    var title = _a.title, description = _a.description, onDismiss = _a.onDismiss, onConfirm = _a.onConfirm, ratingScore = _a.score, allowComment = _a.allowComment, commentLabel = _a.commentLabel, confirmLabel = _a.confirmLabel, loading = _a.loading;
    var _b = useState(null), comment = _b[0], setComment = _b[1];
    var _c = useState(ratingScore !== null && ratingScore !== void 0 ? ratingScore : 0), score = _c[0], setScore = _c[1];
    var handleConfirm = function () { return onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(score, comment); };
    var handleChange = function (e) {
        setComment(e.target.value);
    };
    var handleScore = function (newScore) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        setScore(newScore);
    }; };
    return (React.createElement(View, { onDismiss: onDismiss, loading: loading },
        React.createElement("div", { className: FLEX_CONTAINER_STYLES },
            React.createElement(Content.HeadingSmall, null, title),
            React.createElement(Content.Body, null, description),
            React.createElement("div", { className: RATING_CONTAINER_STYLES }, new Array(5).fill('').map(function (_, i) {
                var active = score - 1 >= i;
                return (React.createElement("button", { className: concatStyles([
                        RATING_STYLES,
                        active
                            ? ACTIVE_RATING_BACKGROUND_STYLES
                            : DEFAULT_RATING_BACKGROUND_STYLES,
                        active
                            ? ACTIVE_RATING_COLOR_STYLES
                            : DEFAULT_RATING_COLOR_STYLES,
                    ]), key: "ratingScore" + i, onClick: handleScore(i + 1) },
                    React.createElement("p", { className: "m-auto" },
                        React.createElement(FontAwesomeIcon, { icon: active ? faStar : faStarOutline }))));
            })),
            allowComment ? (React.createElement("div", { className: COMMENT_STYLES },
                React.createElement(Form.Label, null, commentLabel !== null && commentLabel !== void 0 ? commentLabel : 'Do you want to provide any feedback? (optional):'),
                React.createElement(Input.TextArea, { value: comment !== null && comment !== void 0 ? comment : '', onChange: handleChange }))) : null,
            React.createElement(Form.ButtonGroup, { direction: "row" }, onConfirm ? (React.createElement(Form.Button, { onClick: handleConfirm, disabled: score < 1 },
                React.createElement("span", null, confirmLabel !== null && confirmLabel !== void 0 ? confirmLabel : 'Confirm'))) : null))));
};
