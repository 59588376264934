export var flat = function (arr, d) {
    if (d === void 0) { d = 1; }
    return typeof arr.flat === 'function' ? arr.flat(d) : flatDeep(arr, d);
};
export var flatDeep = function (arr, d) {
    if (d === void 0) { d = 1; }
    return d > 0
        ? arr.reduce(function (acc, val) {
            return acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val);
        }, [])
        : arr.slice();
};
export var flattenObject = function (obj, roots, sep) {
    if (roots === void 0) { roots = []; }
    if (sep === void 0) { sep = '.'; }
    return Object.keys(obj).reduce(function (memo, prop) {
        var _a;
        return Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? flattenObject(obj[prop], roots.concat([prop]))
            : (_a = {}, _a[roots.concat([prop]).join(sep)] = obj[prop], _a));
    }, {});
};
