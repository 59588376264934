import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InactiveContent } from './InactiveContent';
import { concatStyles } from 'utils';
var BOX_STYLES = 'box-border m-0';
var INACTIVE_TEXT_STYLES = 'font-body text-base text-blankSlateInactiveContent';
var INACTIVE_TEXT_COMPACT_STYLES = 'font-body text-sm text-blankSlateInactiveContent font-light text-left';
export var BlankSlate = function (_a) {
    var color = _a.color, icon = _a.icon, message = _a.message, className = _a.className;
    return (React.createElement(InactiveContent, { flexDirection: "col", className: className },
        React.createElement("div", { className: concatStyles([BOX_STYLES]) },
            icon ? React.createElement(FontAwesomeIcon, { color: color, icon: icon, size: "4x" }) : null,
            React.createElement("p", { className: INACTIVE_TEXT_STYLES, style: { color: color } }, message))));
};
export var CompactBlankSlate = function (_a) {
    var color = _a.color, icon = _a.icon, message = _a.message, className = _a.className;
    return (React.createElement(InactiveContent, { flexDirection: "row", className: className },
        icon ? (React.createElement("div", { className: concatStyles([BOX_STYLES, 'my-auto mr-4']) },
            React.createElement(FontAwesomeIcon, { color: color, icon: icon, size: "2x" }))) : null,
        React.createElement("p", { className: INACTIVE_TEXT_COMPACT_STYLES, style: { color: color } }, message)));
};
BlankSlate.displayName = 'Content.BlankSlate';
