var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DateTime } from 'luxon';
import React from 'react';
import * as Avatar from '../Avatar';
import { truncate } from 'utils';
import * as Content from '../Content';
import { Title } from './Title';
import { Subtitle } from './Subtitle';
import { Body } from './Body';
import { View } from './View';
export var Message = function (_a) {
    var _b;
    var message = _a.message, box = __rest(_a, ["message"]);
    var participantCount = parseInt("" + message.participantCount);
    return (React.createElement(View, __assign({}, box),
        React.createElement("div", { className: "mr-2" },
            React.createElement(Avatar.Simple, { name: message.from, maxInitials: 2, imageUrl: message.avatarUrl, color: message.color })),
        React.createElement("article", { className: "flex flex-grow" },
            React.createElement("div", { className: "flex flex-col flex-grow" },
                message.eventName ? (React.createElement("section", { className: "flex" },
                    React.createElement(Avatar.Simple, { name: message.eventName, imageUrl: message.eventIconUrl, maxInitials: 1, size: 18, color: message.color, className: "mr-2" }),
                    React.createElement(Subtitle, null, truncate((_b = message.eventName) !== null && _b !== void 0 ? _b : '', 14)))) : null,
                React.createElement("section", { className: "flex" },
                    React.createElement(Title, null, message.from),
                    ' ',
                    participantCount > 2 ? (React.createElement("p", { className: "pl-2" },
                        React.createElement(Content.Bubble, null,
                            "+",
                            participantCount - 1))) : null),
                React.createElement(Body, null, truncate(message.content, 50))),
            React.createElement("div", { className: "flex flex-shrink-0 flex-grow-0" },
                React.createElement(Body, null, DateTime.fromISO(message.updatedAt).toFormat('M/dd/yyyy'))))));
};
Message.displayName = 'Card.Message';
