var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// Utils
import { remCalc, concatStyles } from 'utils';
import { Indicators } from '../..';
import { NavigatorContext } from './NavigatorContext';
var DRAWER_VIEW_STYLES = 'flex flex-col flex-grow flex-shrink-0 z-10 w-full text-navContent bg-navBackground overflow-y-auto fixed pb-0 md:relative md:w-64';
var FIXED_DRAWER_VIEW_STYLES = 'flex flex-col flex-grow flex-shrink-0 text-navContent bg-navBackground w-full overflow-y-auto fixed pb-0 xl:max-w-xs left-0';
var PRIMARY_STYLES = 'z-10';
var SECONDARY_STYLES = 'bg-navBackgroundAlt pb-32 z-20 hidden md:flex';
var FIXED_DRAWER_VIEW_INLINE_STYLES = {
    height: "calc(100vh - " + remCalc(64) + ")",
};
var DRAWER_ITEM_LIST = 'list-none flex flex-col flex-grow p-0 m-0 flex-shrink-0';
/**
 * The primary navigation drawer
 * @param props Child components and layout configuration
 */
export var Drawer = function (props) {
    var left = props.hasSibling
        ? props.hasCompactSibling
            ? 'md:left-16'
            : 'md:left-64'
        : '';
    return (React.createElement(NavigatorContext.Provider, { value: {
            compact: props.compact || false,
            theme: props.theme || 'primary',
        } }, props.relative ? (React.createElement("div", { className: DRAWER_VIEW_STYLES },
        React.createElement("ul", { className: concatStyles(DRAWER_ITEM_LIST, props.push && 'pt-16') }, React.Children.toArray(props.children).filter(function (c) { return c; })))) : (React.createElement("nav", { style: __assign({}, FIXED_DRAWER_VIEW_INLINE_STYLES), className: concatStyles(FIXED_DRAWER_VIEW_STYLES, props.theme === 'secondary' ? SECONDARY_STYLES : PRIMARY_STYLES, props.compact ? 'md:w-16' : 'md:w-64', left) },
        props.loading ? (React.createElement(Indicators.AbsoluteOverlay, null,
            React.createElement(Indicators.Spinner, { size: "3x" }))) : null,
        React.createElement("ul", { className: concatStyles(DRAWER_ITEM_LIST, props.push && 'pt-16') }, React.Children.toArray(props.children).filter(function (c) { return c; }))))));
};
