import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { concatStyles, useBreakpoint, remCalc } from 'utils';
export var DRAWER_WIDTH = 256;
export var COMPACT_DRAWER_WIDTH = 60;
var calculatedPadding = function (drawerActive, drawerCompact, secondaryDrawerPresent) {
    if (drawerActive) {
        var result = secondaryDrawerPresent
            ? drawerCompact
                ? DRAWER_WIDTH + COMPACT_DRAWER_WIDTH
                : DRAWER_WIDTH * 2
            : DRAWER_WIDTH;
        return secondaryDrawerPresent
            ? drawerCompact
                ? remCalc(DRAWER_WIDTH + COMPACT_DRAWER_WIDTH)
                : remCalc(DRAWER_WIDTH * 2)
            : remCalc(DRAWER_WIDTH);
    }
    if (secondaryDrawerPresent) {
        return remCalc(DRAWER_WIDTH);
    }
    return 0;
};
var DRAWER_LAYOUT_STYLES = 'flex m-0 box-border pt-16';
export var DrawerManagerContext = React.createContext({
    drawerActive: false,
    drawerCompact: false,
    toggleDrawer: function () {
        return;
    },
    useDisplayMode: function (_) {
        return;
    },
});
export var DrawerManager = function (_a) {
    var children = _a.children, forceHideNav = _a.forceHideNav;
    var _b = useState(false), drawerActive = _b[0], setDrawerActive = _b[1];
    var _c = useState(false), isMobile = _c[0], setIsMobile = _c[1];
    var pathname = useLocation().pathname;
    var _d = useState('expanded'), displayMode = _d[0], setDisplayMode = _d[1];
    var viewPorts = useBreakpoint();
    var useDisplayMode = function (newMode) {
        if (newMode === displayMode) {
            return;
        }
        setDisplayMode(newMode);
    };
    var toggleDrawer = function () {
        setDrawerActive(!drawerActive);
    };
    useEffect(function () {
        setDrawerActive(isMobile ? false : !forceHideNav);
    }, [forceHideNav, isMobile, setDrawerActive]);
    useEffect(function () {
        setIsMobile(!viewPorts.includes('md'));
    }, [setIsMobile, viewPorts]);
    useEffect(function () {
        if (typeof window !== 'undefined') {
            if (isMobile && pathname) {
                setDrawerActive(false);
            }
        }
    }, [pathname, isMobile]);
    var drawerCompact = displayMode === 'compact' && !viewPorts.includes('xl');
    var secondaryDrawerPresent = ['expandedWithSecondary', 'compact'].includes(displayMode);
    var isMinMd = viewPorts.includes('md');
    var paddingLeft = isMinMd
        ? calculatedPadding(drawerActive, drawerCompact, secondaryDrawerPresent)
        : undefined;
    var style = { paddingLeft: paddingLeft };
    return (React.createElement("div", { style: style, className: concatStyles(DRAWER_LAYOUT_STYLES) },
        React.createElement(DrawerManagerContext.Provider, { value: {
                drawerActive: drawerActive,
                drawerCompact: drawerCompact,
                toggleDrawer: toggleDrawer,
                useDisplayMode: useDisplayMode,
            } }, children)));
};
