import React from 'react';
import { concatStyles } from 'utils';
export var Size;
(function (Size) {
    Size[Size["default"] = 748] = "default";
    Size[Size["medium"] = 512] = "medium";
    Size[Size["small"] = 384] = "small";
    Size[Size["xl"] = 1200] = "xl";
    Size["screen"] = "100vw";
})(Size || (Size = {}));
var CONTAINER_STYLES = 'flex bg-primary fixed top-0 left-0 w-screen h-screen m-0 overflow-auto sm:items-center z-50';
var FULL_OPACITY_BACKGROUND = 'bg-opacity-100';
var TRANSPARENT_OPACITY_BACKGROUND = 'bg-opacity-50';
var LOADING_OPACITY = 'opacity-75';
var FULL_OPACITY = 'opacity-100';
export var Container = function (_a) {
    var transparent = _a.transparent, loading = _a.loading, disabled = _a.disabled, handleOnClick = _a.onClick, children = _a.children;
    return (React.createElement("article", { style: { pointerEvents: loading || disabled ? 'none' : 'all' }, className: concatStyles([
            CONTAINER_STYLES,
            transparent ? TRANSPARENT_OPACITY_BACKGROUND : FULL_OPACITY_BACKGROUND,
            loading ? LOADING_OPACITY : FULL_OPACITY,
        ]), onClick: handleOnClick }, children));
};
var HEADING_STYLES = 'flex my-4 mx-auto text-contentAreaBackground';
export var Heading = function (_a) {
    var children = _a.children;
    return (React.createElement("header", { className: HEADING_STYLES }, children));
};
var CONTENT_REGION_STYLES = 'flex flex-col relative xs:w-full xs:m-0 sm:w-9/12 sm:m-auto';
export var ContentRegion = function (_a) {
    var size = _a.size, handleOnClick = _a.onClick, children = _a.children;
    return (React.createElement("div", { style: { maxWidth: size + "px" }, className: CONTENT_REGION_STYLES, onClick: handleOnClick }, children));
};
