import React from 'react';
import { concatStyles } from 'utils';
var BUBBLE_STYLES = 'flex bg-metricBackground rounded';
var METRIC_VALUE_STYLES = 'm-auto font-body text-xs text-white p-2';
export var MetricBubble = function (_a) {
    var value = _a.value, style = _a.style, className = _a.className;
    return (React.createElement("div", { className: concatStyles([BUBBLE_STYLES, className]), style: style },
        React.createElement("div", { className: METRIC_VALUE_STYLES }, value)));
};
MetricBubble.displayName = 'Content.MetricBubble';
