var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from '../styled-components';
export var IconLocation;
(function (IconLocation) {
    IconLocation[IconLocation["End"] = 0] = "End";
    IconLocation[IconLocation["Start"] = 1] = "Start";
})(IconLocation || (IconLocation = {}));
// eslint-disable-next-line
var FieldIcon = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  position: absolute;\n  align-content: center;\n  margin: auto 16px;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n  left: ", ";\n  right: ", ";\n  opacity: ", ";\n  pointer-events: none;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"], ["\n  color: ", ";\n  position: absolute;\n  align-content: center;\n  margin: auto 16px;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n  left: ", ";\n  right: ", ";\n  opacity: ", ";\n  pointer-events: none;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"])), function (p) { return (p.active ? p.theme.colorActionable : p.theme.colorBody); }, function (p) { return (p.align === 'left' ? 0 : 'auto'); }, function (p) { return (p.align === 'left' ? 'auto' : 0); }, function (p) { return (p.active ? 1 : 0.5); });
export var Icon = function (_a) {
    var align = _a.align, icon = _a.icon, location = _a.location, active = _a.active;
    return (React.createElement(FieldIcon, { location: location || IconLocation.End, active: active, align: align },
        React.createElement(FontAwesomeIcon, { icon: icon })));
};
var templateObject_1;
