import React from 'react';
var BLOCKQUOTE_STYLES = 'flex flex-col m-0 box-border bg-secondary p-8';
var QUOTE_STYLES = 'box-border text-sm md:text-lg font-heading italic text-contentAreaBackground m-auto text-center';
var AUTHOR_STYLES = 'text-xs md:text-sm font-heading text-accent mt-2 mx-auto mb-auto';
var ICON_STYLES = 'mt-4 mx-auto mb-auto h-12 opacity-75';
export var SplashQuote = function (_a) {
    var quote = _a.quote, author = _a.author, icon = _a.icon, title = _a.title;
    return (React.createElement("blockquote", { className: BLOCKQUOTE_STYLES },
        React.createElement("div", { className: QUOTE_STYLES }, quote),
        author ? (React.createElement("div", { className: AUTHOR_STYLES },
            React.createElement("cite", null, author),
            title ? ',' : '',
            " ",
            title)) : null,
        icon ? React.createElement("div", { className: ICON_STYLES }, icon) : null));
};
