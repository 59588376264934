import { useState, useMemo } from 'react';
import { useEventListener } from './useEventListener';
/**
 * Indicates whether or not a key has been pressed.
 * @param targetKey The key to listen for.
 */
export var useKeyPress = function (targetKey) {
    var _a = useState(false), keyPressed = _a[0], setKeyPressed = _a[1];
    var kewDownHandler = useMemo(function () {
        return function (_a) {
            var key = _a.key;
            if (key === targetKey) {
                setKeyPressed(true);
            }
        };
    }, [targetKey]);
    useEventListener({
        type: 'keydown',
        listener: kewDownHandler,
    });
    var keyUpHandler = useMemo(function () {
        return function (_a) {
            var key = _a.key;
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };
    }, [targetKey]);
    useEventListener({
        type: 'keyup',
        listener: keyUpHandler,
    });
    return keyPressed;
};
