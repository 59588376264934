import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Components
import { DrawerItem } from './DrawerItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigatorContext } from './NavigatorContext';
// Utils
import { concatStyles } from 'utils';
var HREF_STYLES = 'flex flex-row flex-grow py-2 px-3 min-w-0 font-body text-base no-underline';
export var Href = function (_a) {
    var appearanceTheme = _a.appearanceTheme, active = _a.active, children = _a.children, href = _a.href, handleOnClick = _a.onClick;
    var isSecondary = appearanceTheme === 'secondary';
    var HREF_BACKGROUND_STYLES = active
        ? isSecondary
            ? 'bg-navBackgroundActiveAlt'
            : 'bg-contentAreaBackgroundAlt'
        : 'bg-transparent';
    var HREF_COLOR_STYLES = active
        ? isSecondary
            ? 'text-white'
            : 'text-navBackground'
        : 'text-navContent';
    var HREF_HOVER_STYLES = active
        ? 'hover:bg-contentAreaBackgroundAlt hover:text-navBackground'
        : 'hover:bg-actionable hover:text-contentAreaBackground';
    return (React.createElement("a", { href: href, onClick: handleOnClick, className: concatStyles(HREF_STYLES, HREF_BACKGROUND_STYLES, HREF_COLOR_STYLES, HREF_HOVER_STYLES) }, children));
};
Href.displayName = 'Navigator.Href';
var SUB_HREF_STYLES = 'flex flex flex-grow py-2 px-3 min-w-0 font-body text-sm no-underline';
export var SubHref = function (_a) {
    var appearanceTheme = _a.appearanceTheme, active = _a.active, children = _a.children, href = _a.href, handleOnClick = _a.onClick, selected = _a.selected;
    var isSecondary = appearanceTheme === 'secondary';
    var SUB_HREF_BACKGROUND_STYLES = active
        ? isSecondary
            ? 'bg-navBackgroundActiveAlt'
            : 'bg-contentAreaBackgroundAlt'
        : 'bg-transparent';
    var SUB_HREF_COLOR_STYLES = active
        ? isSecondary
            ? 'text-contentAreaBackground'
            : 'text-navBackground'
        : 'text-navContent';
    var SUB_HREF_HOVER_STYLES = active
        ? 'hover:bg-contentAreaBackgroundAlt hover:text-navBackground'
        : 'hover:bg-actionable hover:text-contentAreaBackground';
    return (React.createElement("a", { href: href, onClick: handleOnClick, className: concatStyles(SUB_HREF_STYLES, SUB_HREF_BACKGROUND_STYLES, SUB_HREF_COLOR_STYLES, SUB_HREF_HOVER_STYLES, selected ? 'font-semibold' : 'font-light') }, children));
};
SubHref.displayName = 'Navigator.SubHref';
var LABEL_STYLES = 'flex flex-row my-auto mr-3 ml-0 xl:flex';
export var Label = function (_a) {
    var compact = _a.compact, _b = _a.style, style = _b === void 0 ? {} : _b, children = _a.children;
    return (React.createElement("span", { className: concatStyles(LABEL_STYLES, 'md:hidden' && compact), style: style }, children));
};
Label.displayName = 'Navigator.Label';
var LINK_STYLES = 'flex flex-row flex-grow py-2 px-3 h-16 min-w-0  text-base font-body no-underline';
export var Link = function (_a) {
    var children = _a.children, to = _a.to, active = _a.active, renderCompact = _a.renderCompact, appearanceTheme = _a.appearanceTheme;
    var isSecondary = appearanceTheme === 'secondary';
    var LINK_BACKGROUND_STYLES = active
        ? isSecondary
            ? 'bg-navBackgroundActiveAlt'
            : renderCompact
                ? 'bg-navBackgroundAlt'
                : 'bg-contentAreaBackgroundAlt'
        : isSecondary
            ? 'bg-transparent'
            : 'bg-transparent';
    var LINK_HOVER_STYLES = active
        ? 'hover:bg-contentAreaBackgroundAlt hover:text-navBackground'
        : 'hover:bg-actionable hover:text-contentAreaBackground';
    var LINK_COLOR_STYLES = active
        ? isSecondary
            ? 'text-white'
            : renderCompact
                ? 'text-contentAreaBackground'
                : 'text-navBackground'
        : 'text-navContent';
    return (React.createElement(RouterLink, { to: to, className: concatStyles(LINK_STYLES, LINK_BACKGROUND_STYLES, LINK_COLOR_STYLES, LINK_HOVER_STYLES) }, children));
};
Link.displayName = 'Navigator.Link';
/**
 * An item for the primary navigation drawer.
 * @param props Configuration for the item.
 */
export var Item = function (_a) {
    var active = _a.active, to = _a.to, label = _a.label, labelStyle = _a.labelStyle, icon = _a.icon;
    var _b = useContext(NavigatorContext), compact = _b.compact, theme = _b.theme;
    return (React.createElement(DrawerItem, { appearanceTheme: theme, active: active },
        React.createElement(Link, { appearanceTheme: theme, active: active, renderCompact: compact, to: to },
            icon ? (React.createElement(FontAwesomeIcon, { icon: icon, size: "1x", className: "my-auto mx-3" })) : null,
            compact ? null : React.createElement(Label, { style: labelStyle }, label))));
};
Item.displayName = 'Navigator.Item';
