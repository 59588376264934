var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { concatStyles } from 'utils';
import { Card, Content, Input } from '..';
import Filter from './Filter';
var SHARED_STYLES = 'flex m-0 box-border flex-shrink-0';
var CONTAINER_STYLES = SHARED_STYLES + " flex-row justify-between flex-wrap md:flex-col md:flex-no-wrap w-full";
var INPUT_CONTAINER_STYLES = SHARED_STYLES + " w-full mb-2";
export var FiltersCard = function (_a) {
    var appliedFacets = _a.appliedFacets, facets = _a.facets, attributeTransforms = _a.attributeTransforms, handleToggle = _a.onToggle, initialQueryString = _a.initialQueryString, maxFilterBeforeReveal = _a.maxFilterBeforeReveal, handleQuery = _a.onQuery, hideQueryString = _a.hideQueryString, className = _a.className, style = _a.style, _b = _a.maxVisibleFilters, maxVisibleFilters = _b === void 0 ? 5 : _b;
    var _c = useState(initialQueryString !== null && initialQueryString !== void 0 ? initialQueryString : ''), searchInputText = _c[0], setSearchInputText = _c[1];
    var updateSearchQuery = function (e) {
        setSearchInputText(e.currentTarget.value);
        if (handleQuery) {
            handleQuery(e.currentTarget.value);
        }
    };
    var handleCheckBox = function (value) { return function (checked) {
        handleToggle(value, checked ? 'add' : 'remove');
    }; };
    var _d = useState(false), showAllFilters = _d[0], setShowAllFilters = _d[1];
    var handleToggleFilters = function (e) {
        e.preventDefault();
        setShowAllFilters(!showAllFilters);
    };
    var maxFilters = maxFilterBeforeReveal !== null && maxFilterBeforeReveal !== void 0 ? maxFilterBeforeReveal : 3;
    return (React.createElement(Card.View, { className: concatStyles(['p-2 overflow-y-auto', className]), style: __assign({ maxHeight: '100vh' }, style) },
        React.createElement("div", { className: CONTAINER_STYLES },
            !hideQueryString ? (React.createElement("div", { className: INPUT_CONTAINER_STYLES },
                React.createElement(Input.TextSmall, { onChange: updateSearchQuery, name: "Search Query", placeholder: "Keywords, name, etc.", value: searchInputText }))) : null,
            Object.keys(facets)
                .filter(function (_, i) { return showAllFilters || i < maxFilters; })
                .map(function (attribute) { return (React.createElement(Filter, { key: attribute, attribute: attribute, onChange: handleCheckBox, facet: facets[attribute], appliedFacets: appliedFacets, maxVisibleFilters: maxVisibleFilters, transformLabel: attributeTransforms === null || attributeTransforms === void 0 ? void 0 : attributeTransforms[attribute] })); }),
            Object.keys(facets).length > maxFilters ? (React.createElement(Content.Text, { className: "w-full text-center flex-shrink-0" },
                React.createElement(Content.Href, { className: "cursor-pointer", onClick: handleToggleFilters },
                    showAllFilters ? 'Hide' : 'Show All',
                    " Filters"))) : null)));
};
