var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from 'react';
// Components
import * as Avatar from '../../Avatar';
import * as Content from '../../Content';
import { Label, Link } from './Item';
import { DrawerItem } from './DrawerItem';
import { NavigatorContext, NavigatorItemContext } from './NavigatorContext';
var SHARED_STYLES = 'flex my-auto mr-3 mb-auto ml-0 truncate';
var FONT_XSS = { fontSize: '0.625rem' };
var SECONDARY_LABEL_STYLES = SHARED_STYLES + " font-semibold";
var CONTENTS_STYLES = SHARED_STYLES;
var CONTENT_WRAPPER_STYLES = 'flex box-border m-0 flex-grow max-w-full min-w-0';
/**
 * An item for the primary navigation drawer representing actual
 * content such as a user profile in favor of a general navigation
 * section.
 * @param props Configuration for the item.
 */
export var ContentItem = function (_a) {
    var active = _a.active, color = _a.color, contentStyle = _a.contentStyle, to = _a.to, label = _a.label, itemPrefix = _a.itemPrefix, labelStyle = _a.labelStyle, secondaryLabelStyle = _a.secondaryLabelStyle, maxInitials = _a.maxInitials, children = _a.children, content = _a.content, imageUrl = _a.imageUrl, secondaryLabel = _a.secondaryLabel;
    var navigator = useContext(NavigatorContext);
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var handleMouse = function (over) { return function () {
        if (hovering !== over) {
            setHovering(over);
        }
    }; };
    var colorForPrefix = function () {
        switch (itemPrefix) {
            case 'cancelled':
                return 'destructive';
            case 'draft':
                return 'warning';
            default:
                return undefined;
        }
    };
    return (React.createElement(DrawerItem, { appearanceTheme: navigator.theme, active: active, onMouseOver: handleMouse(true), onMouseOut: handleMouse(false) },
        React.createElement(NavigatorItemContext.Provider, { value: { active: active || false, hovering: hovering, theme: navigator.theme } },
            React.createElement(Link, { to: to, active: active, appearanceTheme: navigator.theme, renderCompact: navigator.compact },
                React.createElement(Avatar.Simple, { className: 'my-auto pointer-events-none', color: color, name: label, imageUrl: imageUrl, size: 42, maxInitials: maxInitials }),
                React.createElement("div", { className: CONTENT_WRAPPER_STYLES },
                    React.createElement("div", { className: "flex flex-col box-border m-0 w-full pointer-events-none flex-auto min-w-0 pl-2" },
                        React.createElement("p", { className: "flex box-border m-0" },
                            itemPrefix ? (React.createElement(Content.Bubble, { bubbleStyle: colorForPrefix() }, itemPrefix)) : null,
                            React.createElement(Label, { style: labelStyle }, label)),
                        secondaryLabel ? (React.createElement("span", { className: SECONDARY_LABEL_STYLES, style: __assign(__assign({}, FONT_XSS), secondaryLabelStyle) }, secondaryLabel)) : null,
                        content ? (React.createElement("span", { className: CONTENTS_STYLES, style: __assign(__assign({}, FONT_XSS), contentStyle) }, content)) : null),
                    children)))));
};
