var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from './View';
import { concatStyles } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { Headline } from './Headline';
import { Body } from './Body';
import * as Avatar from '../Avatar';
import { theme } from '../theme';
var NEW_ITEM_STYLES = 'flex-col text-center p-4 bg-contentAreaBackgroundAlt';
var ACCESSORY_STYLES = 'absolute top-0 right-0 mr-3 mt-3 text-navBackgroundAlt';
var ICON_WRAPPER_STYLES = 'flex rounded-full h-20 w-20 bg-actionable text-white mx-auto mb-3';
var CONTENT_STYLES = 'mx-auto mb-3 text-body';
export var ProfileAction = function (_a) {
    var title = _a.title, description = _a.description, imageUrl = _a.imageUrl, avatarColor = _a.avatarColor, className = _a.className, newItem = _a.newItem, done = _a.done, useProfileIcon = _a.useProfileIcon, view = __rest(_a, ["title", "description", "imageUrl", "avatarColor", "className", "newItem", "done", "useProfileIcon"]);
    return (React.createElement(View, __assign({}, view, { className: concatStyles([NEW_ITEM_STYLES, className]) }),
        done ? (React.createElement("div", { className: ACCESSORY_STYLES },
            React.createElement(FontAwesomeIcon, { icon: faCheckCircle, size: "lg" }))) : null,
        newItem ? (React.createElement("div", { className: ICON_WRAPPER_STYLES },
            React.createElement("p", { className: "m-auto" },
                React.createElement(FontAwesomeIcon, { icon: faPlus, size: "3x" })))) : useProfileIcon ? (React.createElement("p", { className: CONTENT_STYLES },
            React.createElement(FontAwesomeIcon, { icon: faUserCircle, size: "5x" }))) : (React.createElement("div", { className: CONTENT_STYLES },
            React.createElement(Avatar.Simple, { name: title, imageUrl: imageUrl, size: 80, color: avatarColor !== null && avatarColor !== void 0 ? avatarColor : theme.colorActionable, border: true }))),
        React.createElement(Headline, { className: "truncate" }, title),
        React.createElement(Body, { tight: true }, description)));
};
ProfileAction.displayName = 'Card.ProfileAction';
