import { useEffect, useState } from 'react';
export var useImageSize = function (_a) {
    var src = _a.src, crossOrigin = _a.crossOrigin, _b = _a.autoRetry, autoRetry = _b === void 0 ? 0 : _b, onRetry = _a.onRetry;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(0), attempts = _d[0], setAttempts = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(null), img = _f[0], setImg = _f[1];
    var _g = useState({
        height: 0,
        width: 0,
    }), size = _g[0], setSize = _g[1];
    useEffect(function () {
        var imgEl = new Image();
        if (crossOrigin) {
            imgEl.crossOrigin = 'Anonymous';
        }
        imgEl.src = src;
        setLoading(true);
        setError(null);
        imgEl.onload = function () {
            setSize({
                width: imgEl.naturalWidth,
                height: imgEl.naturalHeight,
            });
            setLoading(false);
            setAttempts(0);
        };
        imgEl.onerror = function (e) {
            console.log(e);
            if (attempts < autoRetry && onRetry) {
                setAttempts(attempts + 1);
                onRetry();
            }
            else {
                setError('Could not load image!');
                setLoading(false);
            }
        };
        setImg(imgEl);
    }, [src, setSize, setImg, setLoading, setError]);
    return { size: size, img: img, loading: loading, error: error };
};
