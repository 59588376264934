var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
// ui
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Avatar from '../../Avatar';
import { Dropdown } from './Dropdown';
import { Toggle } from './Toggle';
import { SearchBar } from './SearchBar';
// utils
import { concatStyles } from 'utils';
var NAVIGATOR_CLASS = 'flex flex-row p-0 inset-x-0 fixed h-16 flex-shrink-0 z-20 top-0';
var LOGO_CLASS = 'hidden md:flex my-auto px-4 lg:px-6';
var CLICKABLE_STYLES = 'cursor-pointer';
var PROFILE_NAV_CLASS = 'flex relative my-0 ml-auto';
/**
 * A reusable navigation header w/ search, profile, and primary navigation toggles.
 * @param param0 Props for this component.
 * @see NavigatorHeadingProps
 */
export var Navigator = function (_a) {
    var ProfileNavigation = _a.profileNavigation, drawerActive = _a.drawerActive, profileActive = _a.profileActive, onSearchUpdate = _a.onSearchUpdate, renderResults = _a.renderResults, _b = _a.appearance, appearance = _b === void 0 ? 'primary' : _b, Logo = _a.logo, menuEnabled = _a.menuEnabled, searchEnabled = _a.searchEnabled, profileNavEnabled = _a.profileNavEnabled, handleClickLogo = _a.onClickLogo, p = __rest(_a, ["profileNavigation", "drawerActive", "profileActive", "onSearchUpdate", "renderResults", "appearance", "logo", "menuEnabled", "searchEnabled", "profileNavEnabled", "onClickLogo"]);
    var toggleDrawer = function () {
        if (p.onToggleDrawer) {
            p.onToggleDrawer(!drawerActive);
        }
    };
    var toggleProfile = function () {
        if (p.onToggleProfile) {
            p.onToggleProfile(!profileActive);
        }
    };
    var handleClick = function (e) {
        e.preventDefault();
        if (handleClickLogo) {
            handleClickLogo();
        }
    };
    return (React.createElement("div", { className: concatStyles([
            appearance === 'primary' ? 'bg-primary' : 'bg-secondary',
            NAVIGATOR_CLASS,
        ]) },
        menuEnabled ? (React.createElement(Toggle, { onClick: toggleDrawer, active: drawerActive },
            React.createElement(FontAwesomeIcon, { icon: faBars, size: "2x" }))) : null,
        Logo ? (React.createElement("div", { onClick: handleClick, className: concatStyles(LOGO_CLASS, handleClickLogo && CLICKABLE_STYLES) },
            React.createElement(Logo, null))) : null,
        searchEnabled ? (React.createElement(SearchBar, { onSearchUpdate: onSearchUpdate, renderResults: renderResults })) : null,
        React.createElement("div", { className: PROFILE_NAV_CLASS }, profileNavEnabled ? (React.createElement(React.Fragment, null,
            React.createElement(Toggle, { active: profileActive, onClick: toggleProfile }, p.profileName || p.profileImage ? (React.createElement(Avatar.Simple, { name: p.profileName, color: p.profileColor, imageUrl: p.profileImage, size: 32 })) : (React.createElement(FontAwesomeIcon, { icon: faUserCircle, size: "2x" }))),
            profileActive && ProfileNavigation ? (React.createElement(Dropdown, null,
                React.createElement(ProfileNavigation, null))) : (React.createElement("span", null)))) : null)));
};
