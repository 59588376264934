var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import * as Card from '../../Card';
import { AttachmentPreview } from './AttachmentPreview';
import { useNormalizedUploads } from './useNormalizedUploads';
import { concatStyles, remCalc } from 'utils';
import { DropTarget } from './DropTarget';
import { useEffect } from 'react';
var ATTACHMENTS_STYLES = 'flex flex-grow flex-col my-auto mx-0 p-0';
var INACTIVE_STYLES = 'border-contentAreaBorder';
var CONTAINER_STYLES = 'flex border content-center w-full';
var ACTIVE_STYLES = 'border-actionable';
var ERROR_STYLES = 'text-error font-body text-xs font-semibold text-center w-full mt-2';
var minHeight = remCalc(50);
export var AttachmentList = function (_a) {
    var _b;
    var accept = _a.accept, onUpdate = _a.onUpdate, _c = _a.previews, previews = _c === void 0 ? [] : _c, getEndpointsForFilenames = _a.getEndpointsForFilenames, maxFiles = _a.maxFiles, onDelete = _a.onDelete, onUpload = _a.onUpload, onError = _a.onError, label = _a.label;
    var _d = useNormalizedUploads({
        previews: previews,
        onUpdate: onUpdate,
        accept: accept,
        onDelete: onDelete,
        getEndpointsForFilenames: getEndpointsForFilenames,
        onUpload: onUpload,
        maxFiles: maxFiles,
        onError: onError,
    }), dropzone = _d.dropzone, renderablePreviews = _d.renderablePreviews, uploads = __rest(_d, ["dropzone", "renderablePreviews"]);
    useEffect(function () {
        dropzone.fileRejections.map(function (r) {
            onError === null || onError === void 0 ? void 0 : onError(new Error(r.file.name + " is not an acceptable file type."));
        });
    }, [dropzone.fileRejections]);
    return (React.createElement(Card.View, { internal: true, className: "p-2 flex-col w-full" },
        React.createElement("div", __assign({}, dropzone.getRootProps(), { className: concatStyles([
                CONTAINER_STYLES,
                dropzone.isDragActive ? ACTIVE_STYLES : INACTIVE_STYLES,
            ]), style: { minHeight: minHeight } }),
            React.createElement("input", __assign({}, dropzone.getInputProps())),
            renderablePreviews.length > 0 ? (React.createElement("ul", { className: ATTACHMENTS_STYLES, style: { minHeight: minHeight } }, renderablePreviews.map(function (preview, index) {
                return (React.createElement(AttachmentPreview, { onSort: uploads.handleSort, key: preview.id, preview: preview, onDelete: uploads.handleDeletedFile, index: index, id: preview.id, progress: uploads.progressForUpload(preview.id), onFinishSort: uploads.handleFinishSort }));
            }))) : (React.createElement(DropTarget, { label: label, active: dropzone.isDragActive }))), (_b = dropzone.fileRejections) === null || _b === void 0 ? void 0 :
        _b.map(function (rej) { return (React.createElement("p", { className: ERROR_STYLES },
            rej.file.name,
            " was not accepted.")); })));
};
