import React from 'react';
import { Page } from '../Page';
import { oftmcssColorSrc } from '../../svg';
import { Para } from '../Para';
import { Section } from '../Section';
import { concatStyles } from 'utils';
var DATE_STYLES = 'ml-auto flex text-right';
var ADDRESS_STYLES = 'flex flex-col mr-auto';
var FOOTER_STYLES = 'mt-auto text-center flex flex-col';
var FOOTER_LIST_STYLES = 'flex flex-row text-xs -mx-2';
var FOOTER_ITEM_STYLES = 'first:pl-1 pr-3 last:pr-0 list-disc first:list-none whitespace-nowrap list-inside';
var LINK_STYLES = '-mt-1 text-actionable font-bold underline text-xs';
export var Letter = function (_a) {
    var children = _a.children, name = _a.name, logoSrc = _a.logoSrc, date = _a.date, street = _a.street, street2 = _a.street2, city = _a.city, state = _a.state, zip = _a.zip, hideStationary = _a.hideStationary, preview = _a.preview;
    return (React.createElement(Page, { preview: preview },
        React.createElement("header", { id: "logo", className: concatStyles(hideStationary && 'opacity-0') },
            React.createElement("img", { src: logoSrc !== null && logoSrc !== void 0 ? logoSrc : oftmcssColorSrc, style: { width: '3in' }, className: "h-auto block" })),
        React.createElement(Section, null,
            React.createElement(Para, { className: DATE_STYLES }, date)),
        React.createElement(Section, null,
            React.createElement("ul", { className: ADDRESS_STYLES, id: "address" },
                React.createElement("span", null, name),
                React.createElement("span", { className: "uppercase" }, street),
                street2 ? React.createElement("span", { className: "uppercase" }, street2) : null,
                React.createElement("span", { className: "uppercase" },
                    city,
                    " ",
                    state,
                    " ",
                    zip))),
        children,
        React.createElement("footer", { id: "footer", className: concatStyles(hideStationary && 'opacity-0', FOOTER_STYLES) },
            React.createElement("ul", { className: FOOTER_LIST_STYLES },
                React.createElement("li", { className: FOOTER_ITEM_STYLES }, "4041 N. Central Avenue, Ste. 1200, Phoenix, AZ 85012"),
                React.createElement("li", { className: FOOTER_ITEM_STYLES }, "Phone 602-506-3866"),
                React.createElement("li", { className: FOOTER_ITEM_STYLES }, "Fax 602-506-3753")),
            React.createElement(Para, { className: LINK_STYLES }, "https://schoolsup.org"))));
};
