import React from 'react';
import * as Layout from '../../Layout';
export var NeedHelp = function () { return (React.createElement(Layout.PaddedContent, { className: "font-body" },
    React.createElement("h2", { className: "font-body text-secondary text-3xl text-center font-hairline my-4" }, "Need Help?"),
    React.createElement("div", { className: "flex mx-auto", style: { maxWidth: '48rem' } },
        React.createElement("section", { className: "flex flex-col w-1/2" },
            React.createElement("p", { className: "my-2  text-xs" }, "Homeschooling in Maricopa County starts with a simple filing process for you and your students. This online tool allows you to upload registration documents, receive status updates, download/print confirmation documents, and withdraw students from the program at any time."),
            React.createElement("h3", { className: "text-sm font-semibold" }, "Registration Requirements"),
            React.createElement("p", { className: "my-2 text-xs" }, "If a student will attend a homeschool, the parent or person with custody of the student must submit a notarized affidavit and proof of the student\u2019s identity with the County School Superintendent within 30 days of beginning homeschooling."),
            React.createElement("p", { className: "my-2 text-xs" }, "The most common proof of identity is a certified copy of a birth certificate, which is a photocopy that has been signed and attested to by a public official as a reproduction of the original document. We also accept the student's baptismal certificate, an application for a social security number or original school registration records when accompanied by an affidavit explaining the inability to provide a certified copy of the birth certificate."),
            React.createElement("p", { className: "my-2 text-xs" }, "Please do not file a homeschool affidavit if the student is enrolled in a school, including a virtual charter school (public-school-at-home program) or has a State of Arizona Empowerment Scholarship Account."),
            React.createElement("p", { className: "my-2 text-xs" },
                "For more information, please visit",
                ' ',
                React.createElement("a", { href: "https://schoolsup.org/homeschool", className: "text-actionable" }, "https://schoolsup.org/homeschool"))),
        React.createElement("section", { className: "flex flex-col w-1/2 text-xs ml-8" },
            React.createElement("h3", { className: "text-primary text-xs mt-2 mb-1 font-bold" }, "Homeschool Forms & Resources"),
            React.createElement("p", { className: "text-body mb-2" },
                "View and download the latest forms at", " ",
                React.createElement("a", { href: "https://schoolsup.org/homeschool", className: "text-actionable" }, "https://schoolsup.org/homeschool")),
            React.createElement("h3", { className: "text-primary text-xs mt-2 mb-1 font-bold" }, "Contact Homeschool and Private School Services"),
            React.createElement("a", { href: "/", className: "text-actionable" }, "homeschool1@maricopa.gov"),
            ' ',
            "| (602) 506-3144")))); };
NeedHelp.displayName = 'NeedHelp';
