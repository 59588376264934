var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// Utils
import { lighten } from 'polished';
import { isHexColor } from 'utils';
// Components
import { Simple } from '../Avatar';
import { PaddedContent } from '../Layout';
import { Spinner } from '../Indicators';
import { Bubble } from './Bubble';
var setHeroBackgroundImage = function (backgroundUrl) {
    if (backgroundUrl === void 0) { backgroundUrl = ''; }
    return backgroundUrl !== null && backgroundUrl !== void 0 ? backgroundUrl : 'none';
};
var setHeroBackgroundColor = function (avatarColor) {
    if (avatarColor === void 0) { avatarColor = ''; }
    return isHexColor(avatarColor) ? lighten(0.5)(avatarColor) : '#fff';
};
var HERO_CONTAINER_STYLES = 'flex bg-white relative overflow-hidden m-0';
// :Before
var HERO_BACKGROUND_IMAGE_STYLES = 'absolute bg-cover bg-no-repeat';
var HERO_BACKGROUND_IMAGE_INLINE_STYLES = {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url(graphic-to-be-filtered.jpg)',
    filter: 'blur(7px) brightness(200%) opacity(20%) grayscale(100%)',
};
var HERO_CONTENT_STYLES = 'relative';
var HERO_TITLE_STYLES = 'text-secondary font-heading text-2xl font-semibold uppercase my-2 mx-0';
var HERO_SUBTITLE_STYLES = 'text-primary text-base font-light font-heading uppercase mx-0 mt-0 mb-2';
/**
 * Renders a hero providing an overview of a given profile.
 * @param props Provides the loading state and profile to render.
 */
export var Hero = function (_a) {
    var loading = _a.loading, title = _a.title, subtitle = _a.subtitle, secondarySubtitle = _a.secondarySubtitle, avatarColor = _a.avatarColor, avatarUrl = _a.avatarUrl, backgroundImageUrl = _a.backgroundImageUrl, bubble = _a.bubble, bubbleStyle = _a.bubbleStyle, customTitles = _a.customTitles, customSubtitles = _a.customSubtitles;
    var PLACEHOLDER = '-----';
    return (React.createElement("div", { className: HERO_CONTAINER_STYLES, style: { backgroundColor: setHeroBackgroundColor(avatarColor) } },
        React.createElement("div", { className: HERO_BACKGROUND_IMAGE_STYLES, style: __assign(__assign({}, HERO_BACKGROUND_IMAGE_INLINE_STYLES), { backgroundImage: "url(" + setHeroBackgroundImage(backgroundImageUrl) + ")" }) }),
        React.createElement("div", { className: HERO_CONTENT_STYLES },
            React.createElement(PaddedContent, null,
                React.createElement("div", { className: "flex py-4 px-0" },
                    loading ? (React.createElement(Spinner, { size: "3x" })) : (React.createElement(Simple, { color: avatarColor, name: title, imageUrl: avatarUrl, size: 118, maxInitials: 2 })),
                    React.createElement("div", { className: "my-auto ml-4" },
                        React.createElement("div", { className: "flex flex-col" },
                            bubble ? (React.createElement("div", { className: "mr-auto" },
                                React.createElement(Bubble, { bubbleStyle: bubbleStyle }, bubble))) : null,
                            customTitles ? (customTitles.map(function (c, i) { return (React.createElement("h2", { className: HERO_TITLE_STYLES, key: "customTitle" + i }, loading ? PLACEHOLDER : c)); })) : (React.createElement("h2", { className: HERO_TITLE_STYLES }, loading ? PLACEHOLDER : title)),
                            customSubtitles ? (customSubtitles.map(function (c, i) { return (React.createElement("h2", { className: HERO_SUBTITLE_STYLES, key: "customSubtitle" + i }, loading ? PLACEHOLDER : c)); })) : (React.createElement("h2", { className: HERO_SUBTITLE_STYLES },
                                loading ? PLACEHOLDER : subtitle !== null && subtitle !== void 0 ? subtitle : PLACEHOLDER,
                                secondarySubtitle ? (React.createElement(React.Fragment, null,
                                    React.createElement("br", null),
                                    loading ? PLACEHOLDER : secondarySubtitle)) : null)))))))));
};
Hero.displayName = 'Content.Hero';
