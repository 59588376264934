import * as React from 'react';
import * as Card from '../Card';
import * as Indicators from '../Indicators';
import * as Modal from './Modal';
export var View = function (_a) {
    var children = _a.children, loading = _a.loading, onDismiss = _a.onDismiss, disabled = _a.disabled, _b = _a.size, size = _b === void 0 ? Modal.Size.small : _b;
    var handleClick = function (e) { return e.stopPropagation(); };
    return (React.createElement(Modal.Container, { onClick: onDismiss, transparent: true, disabled: disabled },
        React.createElement(Modal.ContentRegion, { size: size, onClick: handleClick },
            React.createElement(Card.View, null, loading ? (React.createElement("div", { className: "m-auto p-4" },
                React.createElement(Indicators.Spinner, { size: "4x" }))) : (children)))));
};
