import { AccessLevel } from './types';
/**
 * Returns true if the current state indicates the user is authenticated.
 * @param state The current session state.
 */
export var isAuthenticated = function (state) { return state.isAuthenticated; };
/**
 * Returns true if the current state indicates the user lost their session due to an error.
 * @param state The current session state.
 */
export var hasInvalidSession = function (state) {
    return state.sessionInvalidated;
};
/**
 * Returns true if the current state indicates the user is an admin.
 * @param state The current session state.
 */
export var isAdmin = function (state) {
    return state.accessLevel === AccessLevel.Admin;
};
